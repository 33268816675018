import React from 'react';
import '../style/header';
import { Col, Carousel } from 'antd';
import { FlexRow, Divider, Responsive } from '../component';
import { translate } from 'react-i18next';
import map from 'lodash/map';
import chunk from 'lodash/chunk';
import autobind from 'autobind-decorator';
import '../style/partnership.sass';

@translate()
export default class Partnership extends React.Component {
  @autobind
  getCorousel(images, justifyContent, noOfBrandPerPage) {
    return (
      <Col xs={19} sm={24} xl={9} className="col-2">
        <Carousel dots={true} className="carousel">
          {map(chunk(images, noOfBrandPerPage), (imgs, idx) => (
            <div key={`img_${idx}`} style={{ textAlign: 'center' }}>
              <FlexRow justify={justifyContent} align="middle" className="">
                {imgs.map((img, j) => (
                  <Col
                    span={24 / noOfBrandPerPage}
                    key={`p_img_${idx}_${j}`}
                    className="carousel-image-container"
                  >
                    <img
                      className="carousel-image"
                      src={img.src}
                      alt={img.name}
                      title={img.name}
                    />
                  </Col>
                ))}
              </FlexRow>
            </div>
          ))}
        </Carousel>
      </Col>
    );
  }

  render() {
    const { t } = this.props;
    const images = [
      require('../images/shell.png'),
      require('../images/esso.png')
    ];

    return (
      <div className="partnership">
        <div className="container bounded-width">
          <FlexRow justify="space-between" align="middle" className="">
            <Col xs={24} xl={12} className="col-1">
              <h1 className="title white">{t('partnership.slogan_one')}</h1>
              <Divider />
            </Col>
            <Responsive id="partnership-carousel" type={['desktop']}>
              {this.getCorousel(images, 'space-even', 1)}
            </Responsive>
            <Responsive id="partnership-carousel" type={['tablet']}>
              {this.getCorousel(images, 'center', 1)}
            </Responsive>
            <Responsive id="partnership-carousel" type={['mobile']}>
              {this.getCorousel(images, 'center', 1)}
            </Responsive>
          </FlexRow>
        </div>
      </div>
    );
  }
}
