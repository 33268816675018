import React from 'react';


export default class Divider extends React.PureComponent {
    render() {

        const style = {};
        if (this.props.center) {
            style.marginLeft = 'auto';
            style.marginRight = 'auto';
        }
        return <div className="divider" style={style} />;
    }
}