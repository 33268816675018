import React from 'react';
import { EventEmitter } from 'fbemitter';
import '../style/referral-program.sass';
import { FlexRow } from '../component';
import { Button, Col } from 'antd';
import autobind from 'autobind-decorator';
import Partnership from './partnership';
import Cta1 from './cta-1';
export default class ReferralProgramRegister extends React.Component {
  constructor() {
    super();
    this.ggxlogo = require('../images/ggx-logo.png');
    this.refereeIcon = require('../images/referral_program/referee_icon.png');
    this.referralBanner = require('../images/referral_program/referral_banner.png');
    this.state = {
      isExpand: false,
      whatsappLink: '',
      paramVrn: '',
    };
  }
  props: {
    eventBus: EventEmitter,
  };

  state: {};

  @autobind
  onRegisterNowClicked() {
    window.open(this.state.whatsappLink, '_blank');
  }

  @autobind
  onTitleClicked() {
    this.setState({ isExpand: !this.state.isExpand });
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const urlParams = new URLSearchParams(window.location.search);
    const paramVrn = urlParams.get('vrn');

    let whatsappLink = '';
    if (paramVrn) {
      if (window.location.href.includes('staging')) {
        whatsappLink = `https://apply-staging.gogoenergy.sg/signup?salesperson=REF_${paramVrn}`;
      } else {
        whatsappLink = `https://apply.gogoxenergy.sg/signup?salesperson=REF_${paramVrn}`;
      }
    } else {
      if (window.location.href.includes('staging')) {
        whatsappLink = 'https://apply-staging.gogoenergy.sg/signup';
      } else {
        whatsappLink = 'https://apply.gogoxenergy.sg/signup';
      }
    }

    this.setState({
      whatsappLink,
      paramVrn,
    });
  }

  render() {
    const { isExpand } = this.state;
    const accordtionTitleClass = isExpand ? 'accordion-title expand' : 'accordion-title';
    return (
      <div className="referral-program">
        <div className="referral-banner">
          <img srcSet={this.referralBanner.srcSet} src={this.referralBanner}></img>
        </div>
        <div className="middle-content-container">
          <div className="col-1-container bounded-width ">
            <h2
              style={{
                fontSize: '42px',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              <span style={{ color: '#0088BF' }}>Apply today to enjoy exclusive rewards!</span>
            </h2>

            <div id="title_border">
              <hr></hr>
            </div>

            <FlexRow
              justify="space-around"
              className="referral-content-block"
              style={{ marginTop: 30, gap: 20 }}
            >
              <Col xl={10} sm={12} xs={24} className="referree-block">
                <div className="upper-block" icon-content="true">
                  <div icon="true">
                    <img style={{ width: '70px' }} src={this.refereeIcon}></img>
                  </div>{' '}
                  Welcome offer from today onwards:
                </div>
                <div className="content-block" text-content="true">
                  <p>
                    1. Simply pump (Esso or Shell) Petrol/ Diesel over 30L within 30 days from the
                    date the relevant fuel card(s) are mailed to receive $20 fuel rebate;{' '}
                  </p>{' '}
                  <br />
                  <p>
                    2. Additionally, pump another (Esso or Shell) Petrol/ Diesel over 50L within day
                    31 to day 60 from the date the relevant fuel card(s) are mailed to receive $30
                    fuel rebate.
                  </p>
                </div>
              </Col>
            </FlexRow>

            <div style={{ marginTop: '50px', marginBottom: '40px' }}>
              <h2
                style={{
                  fontSize: '38px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                <span style={{ color: '#0088BF' }}>
                  Don't wait! Apply for the GoGoX Energy fuel card now!
                </span>
              </h2>

              <div
                style={{
                  fontSize: '18px',
                  textAlign: 'center',
                  color: 'black',
                }}
              >
                <span>(Click "APPLY NOW" without entering the referral code)</span>
              </div>
            </div>

            <FlexRow style={{ marginTop: '20px' }} justify="center" align="middle">
              <Button
                className="full-width"
                type="info"
                size="large"
                onClick={this.onRegisterNowClicked}
              >
                APPLY NOW
              </Button>
            </FlexRow>
          </div>
        </div>
        <Partnership />
        <Cta1 eventBus={this.props.eventBus} isReferral={true} />
        <div className="tc-container">
          <div className="bounded-width">
            <div
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '16px',
              }}
            >
              <span className={accordtionTitleClass} onClick={this.onTitleClicked}>
                *Referral Program Terms & Conditions:
              </span>
            </div>
            <div className="content-wrapper" style={{ display: isExpand ? 'block' : 'none' }}>
              <ol>
                <li>
                  These terms and conditions (<b>T&Cs</b>) apply to any individual (<b>Referrer</b>)
                  who participates in GoGoX Energy’s referral promotion (the <b>Promotion</b>) by
                  referring new individual or new corporate customers accounts (<b>Referees</b>) to
                  sign up for GoGoX Energy fuel card(s) in Singapore.
                </li>
                <li>
                  By participating in the Promotion as a Referrer, you agree to be bound by these
                  T&Cs.
                </li>
                <li>
                  This Promotion commences on 10 March 2025 and ends on 30 April 2025, both days
                  inclusive (the <b>Promotion Period</b>).
                </li>
                <li>
                  In order to benefit from this Promotion:
                  <ol type="a">
                    <li>
                      the Referrer must be a registered GoGoX Energy Account holder (as defined in
                      the GoGoX Energy Terms and Conditions, (the <b>GGE T&Cs</b>)) as of the time
                      of referral;
                    </li>
                    <li>
                      the Referrer must share the designated GoGoX Energy registration link to
                      prospective Referees;
                    </li>
                    <li>
                      the Referrer’s mobile number must be entered by Referees during the Referee’s
                      registration process;
                    </li>
                    <li>
                      the Referee’s vehicle must not be a motorcycle and the Referee must not
                      already be a registered GoGoX Energy Account holder as of the time of
                      referral;
                    </li>
                    <li>
                      Referees must successfully complete the registration process for GoGoX Energy
                      fuel card(s) during the Promotion Period; and
                    </li>
                    <li>
                      Referees must successfully make the requisite fuel purchases and maintain a
                      good credit record with GoGoX Energy within the designated time periods (as
                      further detailed in these T&Cs).
                    </li>
                  </ol>
                </li>
                <li>
                  Each Referrer can refer multiple Referees. Each Referrer can receive multiple
                  Rewards (defined below), with no upper limit on the number of Referees that each
                  Referrer can receive.
                </li>
                <li>
                  If a Referee has been referred by multiple Referrers, the first Referrer recorded
                  in GoGoX Energy’s system shall be deemed as the sole Referrer.
                </li>
                <li>
                  For each individual account referral, each Referrer will receive:
                  <ol type="a">
                    <li>
                      SG$5 Fuel Rebate once the Referee has their Account opened with fuel card(s)
                      issued by GoGoX Energy (the <b>1st Reward</b>); and
                    </li>
                    <li>
                      SG$10 Fuel Rebate once the Referee purchases over 50 litres of fuel within the
                      1st month (the <b>2nd Reward</b>); and{' '}
                    </li>
                    <li>
                      An additional SG$15 Fuel Rebate for each Referee that met the requirements for
                      the Referrer's 1st month and purchases at least 50 litres of fuel in the 2nd
                      month (<b>the 3rd Reward</b>).
                    </li>
                  </ol>
                </li>

                <li>
                  For each corporate account referral, each Referrer will receive:
                  <ol type="a">
                    <li>
                      SG$20 Fuel Rebate once the Referee has their Account opened with fuel card(s)
                      issued by GoGoX Energy (The Referee is required to be a valid Singapore
                      registered company with a valid UEN, and registers with a minimum of 4
                      vehicles); and
                    </li>
                    <li>
                      <p>
                        SG$80 Fuel Rebate once the Referee purchases over 800 litres of fuel within
                        the 1st month (The 1st Reward, 2nd Reward, 3rd Reward under clauses 7(a),
                        7(b), 7(c) and the rewards set out in clauses 8(a) and 8(b) shall
                        collectively be referred to as the <b>Rewards</b>, and each a <b>Reward</b>
                        ).
                        <br />
                        For the purpose of these T&Cs, <b>1st month</b> refers to the 30-day period
                        from the date the relevant fuel card(s) are mailed to the Referee, and the
                        <b> 2nd month</b> refers to the 30-day period subsequent to the 1st month.
                        Notwithstanding the foregoing, all fuel purchases must be made between 10
                        March 2025 and 31 July 2025 (both dates inclusive) to qualify for any
                        Rewards. Any fuel purchases made after 31 July 2025 will not be counted
                        towards the volume requirements for any Rewards, even if such purchases fall
                        within a Referee's 1st month or 2nd month period.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  The Rewards are non-transferable, non-exchangeable, and cannot be converted to
                  cash. All Fuel Rebates awarded under this Promotion will be credited to the
                  Referrer's GoGoX Energy Account and can only be used for fuel purchases at the
                  designated Service Stations.
                </li>
                <li>
                  The Rewards are non-transferable, non-exchangeable, and cannot be converted to
                  cash. All Fuel Rebates awarded under this Promotion will be credited to the
                  Referrer's GoGoX Energy Account and can only be used for fuel purchases at the
                  designated Service Stations.
                </li>
                <li>
                  GoGoX Energy reserves the right to cancel or amend any or all parts of the
                  Promotion and/or modify these T&Cs without prior notice, and its decision on all
                  matters relating to the Promotion shall be final and binding.
                </li>
                <li>
                  All records in relation to the Promotion will be based on GoGoX Energy’s system
                  records.
                </li>
                <li>
                  Definitions used in these T&Cs shall have the same meaning as defined in the GGE
                  T&Cs unless otherwise specified herein.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
