import React from 'react';
import '../style/header';
import { Button, Col, Card } from 'antd';
import { FlexRow, Responsive } from '../component';
import { translate } from 'react-i18next';

import autobind from 'autobind-decorator';
import '../style/cta-1.sass';

@translate()
export default class Cta1 extends React.Component {
  @autobind
  onCheckNowClicked() {
    this.props.eventBus.emit('clickApplyNow');
  }

  render() {
    const { t } = this.props;

    this.cards = [
      {
        image: require('../images/confidence.svg'),
        title: t('cta_one.confidence'),
        description: t('cta_one.confidence_description'),
      },
      {
        image: require('../images/free.svg'),
        title: t('cta_one.free'),
        description: t('cta_one.free_description'),
      },
      {
        image: require('../images/payment.svg'),
        title: t('cta_one.pricing'),
        description: t('cta_one.pricing_description'),
      },
      {
        image: require('../images/security.svg'),
        title: t('cta_one.security'),
        description: t('cta_one.security_description'),
      },
    ];

    return (
      <div className="cta-1">
        <div className="anchor" id="why-choose-us" />
        <div className="col-1-container bounded-width">
          <div className="col-1">
            <FlexRow justify="space-between" align="middle" className=" ">
              <Col className="cell-1" xs={{ span: 24 }} xl={{ span: 10 }}>
                <p className="title banner-head white">{t('cta_one.slogan_one')}</p>
                <p className="body-head white slogan-2">{t('cta_one.slogan_two')}</p>
                <Responsive id="cta-1-apply-btn" type={'desktop'}>
                  <Button
                    style={{ display: this.props.isReferral ? 'none' : 'block' }}
                    type="info"
                    size="large"
                    onClick={this.onCheckNowClicked}
                  >
                    {t('btn.apply_now')}
                  </Button>
                </Responsive>
                <Responsive id="cta-1-apply-btn" type={['tablet', 'mobile']}>
                  <FlexRow justify="center" align="middle" className="cta-1-apply-btn">
                    <Col xl={6} sm={6} xs={24} className="padded-horizontal">
                      <Button
                        style={{ display: this.props.isReferral ? 'none' : 'block' }}
                        className="full-width"
                        type="info"
                        size="large"
                        onClick={this.onCheckNowClicked}
                      >
                        {t('btn.apply_now')}
                      </Button>
                    </Col>
                  </FlexRow>
                </Responsive>
              </Col>

              <Col className="cell-2" xs={{ span: 24 }} xl={{ span: 9 }}>
                <FlexRow align="center">
                  {this.cards.map((card, idx) => (
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 11 }}
                      xl={{ span: 12 }}
                      key={`features-1-${idx}`}
                    >
                      <Responsive id="features-1-card" type={['desktop', 'tablet']}>
                        <Card
                          className="features-1-card"
                          cover={
                            <img
                              src={card.image}
                              alt={card.name}
                              title={card.name}
                              className="card-image"
                            />
                          }
                          bordered={false}
                        >
                          <Card.Meta
                            className="center"
                            title={<p className="white bold card-title">{card.title}</p>}
                            description={
                              <small className="white card-description">{card.description}</small>
                            }
                          />
                        </Card>
                      </Responsive>
                      <Responsive id="features-1-card" type={['mobile']}>
                        <FlexRow align="center" justify="space-between" className="features-1-row">
                          <Col span={8} className="card-image-row">
                            <img
                              src={card.image}
                              alt={card.name}
                              title={card.name}
                              className="card-image"
                            />
                          </Col>
                          <Col span={14} offset={2}>
                            <p className="white bold card-title">{card.title}</p>
                            <small className="white card-description">{card.description}</small>
                          </Col>
                        </FlexRow>
                      </Responsive>
                    </Col>
                  ))}
                </FlexRow>
              </Col>
            </FlexRow>
          </div>
        </div>
      </div>
    );
  }
}
