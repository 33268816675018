import React from 'react';
import '../style/header';
import { Layout, Menu, Button, Col, Icon, Modal } from 'antd';
import { FlexRow, Responsive } from '../component';
import { translate } from 'react-i18next';
import map from 'lodash/map';
import autobind from 'autobind-decorator';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

const AntdHeader = Layout.Header;

@translate()
@withRouter
export default class Header extends React.Component {
  constructor() {
    super();
    this.logo = require('../images/gge-logo2.png');
    this.logoWhite = require('../images/gge-logo-white.png');
    this.state = {
      displayMobileMenu: false,
    };
    this.menuBtns = {
      why_us: process.env.MENU_URL_WHY_US,
      about: process.env.MENU_URL_ABOUT,
      q_a: process.env.MENU_URL_QA,
      my_account: 'https://portal.gogoxenergy.sg/',
    };
  }
  //ref && this.state.displayMobileMenu && ref.classList.add('active')
  smallMenu() {
    const { t } = this.props;

    return (
      <div className="header-container ">
        <div className="mobile-header-bg">
          <FlexRow align={'middle'} justify="space-between" className="mobile-header bounded-width">
            <Col span={12}>
              <Link to="/">
                <img
                  className="logo"
                  src={this.logo.src}
                  srcSet={this.logo.srcSet}
                  alt={this.logo.name}
                  title={this.logo.name}
                />
              </Link>
            </Col>
            <Col span={3}>
              <Button className="menu" id="mobile-menu" onClick={this.toggleMobileMenu}>
                <img src={require('../images/menu-btn.svg')} />
              </Button>
            </Col>
          </FlexRow>
        </div>
        {this.state.displayMobileMenu ? (
          <div className="mobile-menu padded-top">
            <div className="mobile-menu-bg"></div>
            <FlexRow align="center" justify="center" className="mobile-menu-items padded-top">
              {map(this.menuBtns, (url, key) => (
                <Col span={24} className="center padded-bottom padded-top " key={`menu_${key}`}>
                  <a
                    href={url}
                    onClick={this.toggleMobileMenu}
                    target={key === 'my_account' ? '_blank' : ''}
                  >
                    <p className="white center">{t('top_menu.' + key)}</p>
                  </a>
                </Col>
              ))}
            </FlexRow>
          </div>
        ) : null}
      </div>
    );
  }

  @autobind
  handleApplyClick() {
    this.props.eventBus.emit('clickApplyNow');
  }

  @autobind
  toggleMobileMenu() {
    this.setState({
      displayMobileMenu: !this.state.displayMobileMenu,
    });
    if (!this.state.displayMobileMenu) {
      document.getElementById('content-container').classList.add('blur-bg');
    } else {
      document.getElementById('content-container').classList.remove('blur-bg');
    }
  }

  componentDidMount() {
    this.header = document.getElementById('main-header');
    document.onscroll = () => {
      if (!this.header) {
        return;
      }
      const scrollingHeight =
        window.scrollY ||
        window.pageYOffset ||
        document.body.scrollTop +
          ((document.documentElement && document.documentElement.scrollTop) || 0);
      if (scrollingHeight > 0) {
        this.header.classList.add('scrolled-header');
      } else {
        this.header.classList.remove('scrolled-header');
      }
    };
  }

  fullMenu(pathname) {
    console.log(pathname);
    const { t } = this.props;
    return (
      <div className="header-container bounded-width">
        <FlexRow align={'middle'} justify="space-between" className="desktop-header">
          <Col span={4}>
            <Link to="/">
              <div className="logo-container">
                <img
                  className="logo logo-normal"
                  src={this.logo.src}
                  alt={this.logo.name}
                  title={this.logo.name}
                />
                <img
                  className="logo logo-white"
                  src={this.logoWhite.src}
                  alt={this.logoWhite.name}
                  title={this.logoWhite.name}
                />
              </div>
            </Link>
          </Col>
          <Col span={16}>
            <Menu mode="horizontal" className="menu" selectable={false}>
              {map(this.menuBtns, (url, key) => (
                <Menu.Item key={`menu_${key}`}>
                  <a href={url} target={key === 'my_account' ? '_blank' : ''}>
                    {t('top_menu.' + key)}
                  </a>
                </Menu.Item>
              ))}
            </Menu>
          </Col>
          {!pathname.includes('sgdrivers') &&
          !pathname.includes('ggereferral-register') &&
          !pathname.includes('ggereferral') ? (
            <Col span={3} offset={1}>
              <Button onClick={this.handleApplyClick} type="info full-width">
                <Icon type="edit" />
                {t('btn.apply_now')}
              </Button>
            </Col>
          ) : null}
        </FlexRow>
      </div>
    );
  }

  render() {
    const { history } = this.props;
    const { t } = this.props;

    const pathname = history.location.pathname;
    console.log(pathname);
    const classNames = ['header'];
    if (this.state.displayMobileMenu) {
      classNames.push('header-full-height');
    }
    if (pathname !== '/') {
      classNames.push('header-bg spec-header');
    }

    return (
      <AntdHeader className={classNames.join(' ')} id="main-header">
        <Responsive id="_header" type={['mobile', 'tablet']}>
          {this.smallMenu()}
        </Responsive>
        <Responsive id="_header" type={['desktop']}>
          {this.fullMenu(pathname)}
        </Responsive>
      </AntdHeader>
    );
  }
}
