import React from 'react';
import { Modal, Col, Input, Button, Icon, Checkbox, Form } from 'antd';
import { FlexRow } from '../component';
import { translate } from 'react-i18next';
import '../style/application-form.sass';
import { EventEmitter } from 'fbemitter';
const FormItem = Form.Item;
import autobind from 'autobind-decorator';

@translate()
export default class ApplicationForm extends React.Component {
  state: {
    visible: boolean,
    submitted: boolean,
  };
  props: {
    eventBus: EventEmitter,
  };
  images: any[];

  constructor() {
    super();
    this.state = {
      visible: false,
      submitted: false,
    };
    this.logo = require('../images/logo.png');
  }

  componentDidMount() {
    this.props.eventBus.addListener('showApplicationForm', () => {
      this.setState({ visible: true, submitted: false });
      this.props.eventBus.emit('applicationFormShowed');
    });
  }

  componentWillUnmount() {
    this.props.eventBus.removeAllListeners('showApplicationForm');
  }

  @autobind
  close() {
    this.setState({ visible: false });
    this.props.eventBus.emit('applicationFormHidden');
  }

  @autobind
  onSubmit(e) {
    const { t } = this.props;
    e.preventDefault();
    let hasError = false;
    if (!this.state.mobileValue) {
      this.setState({ mobileValueError: true });
      hasError = true;
    }
    if (!this.state.emailValue) {
      this.setState({ emailValueError: true });
      hasError = true;
    }
    if (hasError) {
      return;
    }
    this.setState({
      emailValueError: false,
      mobileValueError: false,
      emailValue: null,
      mobileValue: null,
      submitted: true,
    });
    // message.info(t('application_form.submit_success'));
    window.submitGoogleForm(this.form, process.env.GOOGLE_FORM_URL);
  }

  renderSubmitted() {
    const { t } = this.props;
    return (
      <div className="submitted-container">
        <FlexRow onSubmit={this.onSubmit} justify="center" align="middle">
          <img
            alt={this.logo.name}
            title={this.logo.name}
            className="logo"
            src={this.logo.src}
            srcSet={this.logo.srcSet}
          />
          <Col
            xs={24}
            sm={10}
            dangerouslySetInnerHTML={{
              __html: `<pre>${t('application_form.submit_success', {
                linkStart: `<a href="${process.env.URL_APPLY_NOW}" >`,
                linkEnd: '</a>',
              })}</pre>`,
            }}
          ></Col>
        </FlexRow>
      </div>
    );
  }

  renderForm() {
    const image = require('../images/application-form-bg.jpg');
    const { t } = this.props;
    return (
      <FlexRow className="" onSubmit={this.onSubmit}>
        <Col xs={24} sm={10}>
          <Form
            className="application-form-form"
            ref={ref => {
              this.form = document.getElementsByClassName(
                'application-form-form'
              )[0];
            }}
          >
            {/* <FormItem span={24}> */}
            <img
              alt={this.logo.name}
              title={this.logo.name}
              className="logo"
              src={this.logo.src}
              srcSet={this.logo.srcSet}
            />
            {/* </FormItem> */}
            <FormItem span={24}>
              <p>{t('application_form.slogon')}</p>
            </FormItem>
            <FormItem span={24}>
              <Input
                type="number"
                name={process.env.GOOGLE_FORM_PHONE_FIELD}
                value={this.state.mobileValue}
                className={this.state.mobileValueError ? 'error' : ''}
                onChange={e =>
                  this.setState({
                    mobileValue: e.target.value,
                    mobileValueError: false,
                  })
                }
                addonBefore={<Icon type="mobile" />}
                style={{ width: '100%' }}
                placeholder={t('application_form.tel')}
              />
            </FormItem>
            <FormItem span={24} required={true}>
              <Input
                type="email"
                name={process.env.GOOGLE_FORM_EMAIL_FIELD}
                value={this.state.emailValue}
                className={this.state.emailValueError ? 'error' : ''}
                onChange={e =>
                  this.setState({
                    emailValue: e.target.value,
                    emailValueError: false,
                  })
                }
                addonBefore={<Icon type="mail" />}
                style={{ width: '100%' }}
                placeholder={t('application_form.email')}
              />
            </FormItem>
            <FormItem span={24}>
              <Checkbox
                onChange={e => this.setState({ receiveInfo: e.target.checked })}
              >
                {t('application_form.receive_info')}
              </Checkbox>
              <input
                type="hidden"
                value={this.state.receiveInfo ? 'Yes' : 'No'}
                name={process.env.GOOGLE_FORM_RECEIVE_NEWS_FIELD}
              />
            </FormItem>
            <FormItem span={24}>
              <Button
                type="cyan"
                size="large"
                htmlType="submit"
                className="white apply-now"
              >
                {t('btn.apply_now')}
              </Button>
            </FormItem>
            <FormItem span={24}>
              <small
                className="tc"
                dangerouslySetInnerHTML={{
                  __html: t('application_form.t_c', {
                    tcLinkStart:
                      '<a href="/pdf/term_and_condition.pdf" target="_blank">',
                    tcLinkEnd: '</a>',
                    privacyLinkStart: '<a href="/privacy" target="_blank">',
                    privacyLinkEnd: '</a>',
                  }),
                }}
              ></small>
            </FormItem>
          </Form>
        </Col>
        <Col xs={0} sm={14} className="cover-image-container">
          <img
            className="cover-image"
            src={image.src}
            srcSet={image.srcSet}
            alt={image.name}
            title={image.name}
          />
        </Col>
      </FlexRow>
    );
  }

  render() {
    const mainView = this.state.submitted
      ? this.renderSubmitted()
      : this.renderForm();

    return (
      <Modal
        className={`application-form ${
          this.state.submitted ? 'application-form-submmited' : ''
        }`}
        visible={this.state.visible}
        footer={null}
        closable={true}
        onCancel={this.close}
      >
        {mainView}
      </Modal>
    );
  }
}
