import { EventEmitter } from 'fbemitter';
import React from 'react';
import '../style/privacy-policy';
import SubFooter from './sub-footer';

export default class TermAndCondition extends React.Component {
  props: {
    eventBus: EventEmitter,
  };

  state: {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="legal-term-header"></div>
        <div className="privacy-policy">
          <div className="bounded-width content">
            <h1>Terms & Conditions</h1>
            <p align="JUSTIFY">
              The use and operation of your Account and any and all Cards are governed by these
              terms and conditions. You acknowledge and agree that your use of the Card and the
              Account will be bound by these terms and conditions.
            </p>
            <p align="JUSTIFY">
              Please read these terms and conditions carefully before you use the Card. You will be
              deemed to have accepted these terms and conditions if you (i) sign an Application form
              referring to these terms and conditions that is accepted by us or (ii) use or attempt
              to use the Card and/or the Account. If you do not accept these terms and conditions,
              you must immediately cut the Card in half and promptly return it to us and not use the
              Account in any way.
            </p>
            <ol>
              <li>
                <p align="JUSTIFY">
                  <strong>Definitions and interpretation</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              In these terms and conditions, the following definitions and rules of interpretation
              apply unless the context requires otherwise:
            </p>
            <p align="JUSTIFY">
              “<strong>Account</strong>” means the Account opened by us in your name;
            </p>
            <p align="JUSTIFY">
              “<strong>Account holder</strong>” means the person in whose name the Account is
              maintained;
            </p>
            <p align="JUSTIFY">
              “<strong>Affiliate(s)</strong>” means any legal entity, which is directly or
              indirectly controlling, controlled by or under common control with any of the parties
              to these terms and conditions, or is otherwise in the same group of entities as any of
              the parties to these terms and conditions;
            </p>
            <p align="JUSTIFY">
              “<strong>Application</strong>” means the original, and any subsequent, application
              made by an applicant to open the Account;
            </p>
            <p align="JUSTIFY">
              “<strong>Balance</strong>” means all transactions made using a Card charged to your
              Account and includes all purchases, fees and other amounts that you have agreed to pay
              us or are liable for under these terms and conditions;
            </p>
            <p align="JUSTIFY">
              “<strong>Business Day</strong>” means a day (except Saturdays, Sundays and public
              holidays) on which commercial banks are open for business in Singapore;
            </p>
            <p align="JUSTIFY">
              “<strong>Card</strong>” means each GoGoX Energy card, replacement card or other card
              as we may determine from time to time that is issued by us or the Supplier pursuant to
              a fleet card agreement between us and the Supplier for use in relation to your Account
              (a Card may or may not bear the name of the Cardholder and may be with or without a
              signature panel);
            </p>
            <p align="JUSTIFY">
              “<strong>Cardholder</strong>” means you and any person authorised by you from time to
              time to use a Card;
            </p>
            <p align="JUSTIFY">
              “<strong>Corporate Entity</strong>” means a company, partnership, sole proprietorship,
              or any corporate entity registered and/or incorporated under the laws of Singapore;
            </p>
            <p align="JUSTIFY">
              “<strong>Expenditure Balance</strong>” means, at any time, the total of all amounts
              that have been charged to your Account but which have not been paid;
            </p>
            <p align="JUSTIFY">
              “<strong>Expenditure Limit</strong>” means the amount notified by us to you from time
              to time in accordance with clause 9 as being the maximum allowable Expenditure Balance
              of the Account;
            </p>
            <p align="JUSTIFY">
              “<strong>Fee Schedule</strong>” means the schedule of fees attached to these terms and
              conditions including any variation to it, that form part of these terms and
              conditions;
            </p>
            <p align="JUSTIFY">
              “<strong>GOGOX</strong>” means the GOGOX mobile application developed and supplied by
              GoGo Tech Limited and operated by GOGOX Singapore Pte Ltd in Singapore;
            </p>
            <p align="JUSTIFY">
              “<strong>Late Payment Charge</strong>” means the amount charged to you if you do not
              pay the Balance on the time specified in a Statement or an amount charged to your
              Account when demanded by us;
            </p>
            <p align="JUSTIFY">
              “<strong>Nominated Vehicle</strong>” means, in relation to a Card, the vehicle (if
              any) specified on that Card or, in relation to an Account, the vehicle you nominated
              during the Application for the Account, and the same meaning shall be ascribed to{' '}
              <strong>Nominated Vehicles</strong>;
            </p>
            <p align="JUSTIFY">
              “<strong>Notification Event</strong>” means if:
            </p>
            <ol type="a">
              <li>
                <p align="JUSTIFY">
                  you cease, suspend or threaten to cease or suspend the conduct of all or a
                  substantial part of your business or dispose of or threaten to dispose of a
                  substantial part of your assets;
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  an administrator is appointed over you or any of your assets or an application or
                  order is made, proceedings are commenced, a resolution is passed or proposed in a
                  notice of meeting, an application to a court is made or other steps are taken for
                  you to enter into an arrangement, compromise or composition with or assignment for
                  the benefit of your creditors or a class of them;
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  you are an individual, you appoint a trustee pursuant to applicable bankruptcy
                  laws or a petition for your bankruptcy is issued (except where the petition is no
                  longer in force); or
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  you are a Corporate Entity, an application or order is made, proceedings are
                  commenced, a resolution is passed or proposed in a notice of meeting, an
                  application to a court is made or other steps are taken for your winding up,
                  deregistration, dissolution or administration or for the appointment of a receiver
                  or receiver and manager over any of your assets;
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              “<strong>Notification of Loss</strong>” means the written notification by you to us
              reporting the event(s) pursuant to clause 8 below;
            </p>
            <p align="JUSTIFY">
              “<strong>PIN</strong>” means a Personal Identification Number that may be issued to
              the Cardholder for each Card to enable the Cardholder to make purchases of Products;
            </p>
            <p align="JUSTIFY">
              “<strong>Products</strong>” means the petroleum products or any other products or
              services that may be available for sale at any service station designated by the
              Supplier from time to time, and which you have agreed by way of choice of options in
              the application form of the Card, to be purchased and charged to the Card;
            </p>
            <p align="JUSTIFY">
              “<strong>S$</strong>” means Singapore dollars;
            </p>
            <p align="JUSTIFY">
              “<strong>Service Station</strong>” means a location or station at which the Supplier
              offers the Products for sale, and which is designated by us or the Supplier from time
              to time, such designations to be marked on the Card or notified to you from time to
              time, and the same meaning shall be ascribed to “<strong>Service Stations</strong>”;
            </p>
            <p align="JUSTIFY">
              “<strong>Singapore</strong>” means the Republic of Singapore;
            </p>
            <p align="JUSTIFY">
              “<strong>Statement</strong>” means a statement issued by us pursuant to these terms
              and conditions;
            </p>
            <p align="JUSTIFY">
              “<strong>Supplier</strong>” means the supplier of the Products;
            </p>
            <p align="JUSTIFY">
              “<strong>we</strong>”, “<strong>our</strong>” and “<strong>us</strong>” mean GoGo
              Energy Singapore Pte Ltd, a limited liability company incorporated in Singapore (UEN:
              201424170D), and its successors and assigns; and
            </p>
            <p align="JUSTIFY">
              “<strong>you</strong>” and “<strong>your</strong>” mean the Account holder.
            </p>
            <p align="JUSTIFY">
              Headings are for convenience only and do not affect interpretation. The singular
              includes the plural and conversely. A reference to a person, corporation, trust,
              partnership, unincorporated body or other entity includes any of them.
            </p>
            <ol start="2">
              <li>
                <p align="JUSTIFY">
                  <strong>Account opening, Card issuance and charging of amounts</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              Unless we agree otherwise at our sole and absolute discretion, in order to be eligible
              to apply to open an Account and any Card, you and/or the Cardholder must (a) be a
              registered driver member of GOGOX and (b) agree to exclusively use GOGOX as the only
              mobile, online and wireless platform through which you offer and provide
              transportation and logistics services in Singapore. If we accept your Application, we
              will open an Account in your name. If you are a Corporate Entity, the Account will be
              opened in the name of that Corporate Entity. Upon opening the Account we will, at our
              sole and absolute discretion, issue you the Card you applied for. Any amount payable
              under these terms and conditions will be charged to your Account and recorded in a
              Statement.
            </p>
            <ol start="3">
              <li>
                <p align="JUSTIFY">
                  <strong>Permitted use of Card</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">A Card:</p>
            <ol type="a">
              <li>
                <p align="JUSTIFY">
                  may only be used by the Cardholder and only be used in respect of the Nominated
                  Vehicle(s);
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  may only be used to purchase Products within Singapore at the service stations
                  designated by us or the Supplier from time to time and within any limitations or
                  markings designated on the Card or notified to you from time to time;
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  may not be used outside the validity period shown on it (if any); and
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  may not be used if the Card or Account has been cancelled or suspended, or if the
                  Account has been closed pursuant to these terms and conditions.
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              A Cardholder shall produce the Card to the attendant at a Service Station whenever the
              Cardholder wishes to make a purchase of Products. The attendant will return the Card
              to the Cardholder together with a statement of the Products purchased on that occasion
              should the Cardholder so request. The Cardholder shall be deemed to accept the details
              on the statement as correct unless the Cardholder raises an objection before leaving
              the Service Station. In case no statement is requested or issued by the Service
              Station, the Cardholder shall automatically be deemed to have accepted details of the
              transaction as correct.
            </p>
            <p align="JUSTIFY">
              If the registration number(s) of the Nominated Vehicles(s) is/are not embossed on the
              Card for whatever reason, the Card may be used by anyone who presents it to the
              attendant at the designated service station, and neither us nor the Supplier has any
              obligation to verify the identity of the user(s).
            </p>
            <p align="JUSTIFY">
              Neither the Card nor the Account may be used for an unlawful purpose. You must not
              allow anyone other than a Cardholder to use the Card or the Account. A Cardholder must
              not use the Card if he/she does not reasonably expect to be in a position to pay the
              Balance in full when due or if a Notification Event occurs. You acknowledge and agree
              that we have the right to refuse the processing, completion, or authorisation of any
              transaction without cause or prior notice and that we shall not be liable to you, a
              Cardholder or anyone else for loss or damage resulting from such refusal.
            </p>
            <ol start="4">
              <li>
                <p align="JUSTIFY">
                  <strong>Discounts</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              We may, at our sole and absolute discretion, offer to you, from time to time, such
              amount of discounts and/or rebates for the purchase of Products effected by the use of
              the Card. We reserve the right to revise or terminate any such discounts and/or
              rebates at any time without prior notice to you.
            </p>
            <ol start="5">
              <li>
                <p align="JUSTIFY">
                  <strong>Ownership and return of Card</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              Each Card is, and will be at all times, our property and the property of the Supplier
              and must not be altered, defaced, or tampered with in any way. The Card is not
              transferable. If, for any reason, we cancel a Card or ask you to return a Card, you
              must immediately cut the Card in half and promptly return it to us at your own cost.
              If a Cardholder no longer needs a Card or a Cardholder ceases to be authorised to use
              a Card for any reason whatsoever, you must immediately cut the Cardholder’s Card in
              half and promptly return it to us at your own cost. If any Card is not cut in half and
              returned to us as required by these terms and conditions, you are liable for, and must
              indemnify us on demand in respect of, any loss, damage, costs or expenses (including,
              without limitation, legal  fees/expenses on a full indemnity basis) sustained by us
              arising out of or in connection with any subsequent use of the Card by any person.
            </p>
            <ol start="6">
              <li>
                <p align="JUSTIFY">
                  <strong>Liability for amounts in respect of Card</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              You are liable to pay us, when due, all amounts charged to your Account pursuant to
              these terms and conditions. Except as set out in clause 8 of these terms and
              conditions, we may charge to your Account the amount of any transaction entered into
              by any person using a Card, even if:
            </p>
            <ol type="a">
              <li>
                <p align="JUSTIFY">
                  the Card is used in a way that is not permitted under these terms and conditions;
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  you have withdrawn the authorisation of the Cardholder to use the Card;
                </p>
              </li>
              <li>
                <p align="JUSTIFY">the Card is used by a person other than the Cardholder; or</p>
              </li>
              <li>
                <p align="JUSTIFY">the Card, or any other Card, has been cancelled.</p>
              </li>
            </ol>
            <ol start="7">
              <li>
                <p align="JUSTIFY">
                  <strong>Undertakings</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">You undertake to comply with the following:</p>
            <ol type="a">
              <li>
                <p align="JUSTIFY">
                  to keep the Card safe and ensure that it is used only in accordance with these
                  terms and conditions;
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  to sign the Card immediately on receipt thereof if the Card comes with a signature
                  panel;
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  to take all reasonable care and precaution to prevent the loss, theft or
                  mutilation of any Card;
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  not to reveal the PIN of the Card to any person other than the Cardholder;
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  to reimburse us for all loss, damage, costs or expenses (including, without
                  limitation, legal fees/expenses on a full indemnity basis) which we may pay, incur
                  or sustain in relation to any breach of these terms and conditions by you or any
                  Cardholder, or in relation to any action taken by us to enforce these terms and
                  conditions against you;
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  to immediately inform us if the Cardholder suspects that a Card is being used
                  without the authorisation of the Cardholder;
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  to inform us of any changes of your contact details within seven (7) days of such
                  change;
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  the PIN must not be written, printed or indicated in any manner on the Card;
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  the PIN must be keyed in personally by the Cardholder at the Service Stations and
                  not by any other party; and
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  to comply with all requirements, directions, instructions and measures as may be
                  issued by us and/or the Supplier from time to time, including but not limited to,
                  the terms and conditions issued by the Supplier (if any).
                </p>
              </li>
            </ol>
            <ol start="8">
              <li>
                <p align="JUSTIFY">
                  <strong>Liability for lost or stolen Cards and unauthorised transactions</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              In the event of a loss or theft of any Card or a loss or compromise of the PIN or a
              breach of any of the above obligations in clause 7, you must immediately inform us
              verbally, and immediately send <strong>by email and hand,</strong> a Notification of
              Loss to us advising the same. We will, as soon as is reasonably practicable after
              receiving a Notification of Loss from you, block the Card that has been reported as
              lost or stolen. In such event, your liability for all purchases charged to the Card
              shall be as follows:
            </p>
            <p align="JUSTIFY">
              (a) for all Card transactions incurred prior to the successful blocking of the Card,
              you shall be fully liable for all Balance;
            </p>
            <p align="JUSTIFY">
              (b) for all Card transactions incurred after our receipt of your Notification of Loss
              but subject always to the successful blocking of the Card, you shall have no liability
              for such transactions. Pending the successful blocking of the Card, you shall still be
              fully liable for Balances for Card transactions incurred after our receipt of your
              Notification of Loss.
            </p>
            <p align="JUSTIFY">
              Notwithstanding the remainder of this clause, if you or a Cardholder are involved in,
              or have benefited, directly or indirectly, from, the loss, theft or misuse of the
              Card, the unauthorised transaction or the non-receipt of the renewal Card, your
              obligation to pay amounts charged to the Account will not be affected or limited by
              this clause 8.
            </p>
            <p align="JUSTIFY">
              If you fail to give the Notification of Loss in the manner described herein, you shall
              be fully liable for all charges incurred or accrued against the Card, and your
              liability as such shall not be affected or limited by this clause 8.
            </p>
            <p align="JUSTIFY">
              For the purpose of this clause 8, proof of email or courier transmission is not proof
              of receipt by or delivery to us. Upon receipt of the Notification of Loss by us, we
              may (at our sole and absolute discretion) issue a replacement Card and invalidate the
              existing Card and impose such fee as we deem appropriate. If any existing Card shall
              be subsequently recovered by you or the Cardholder, you must immediately notify us in
              writing and destroy the said Card by cutting it in half and promptly returning it to
              us at your own cost.
            </p>
            <p align="JUSTIFY">
              You have the sole responsibility of monitoring the use of the Card and of notifying us
              immediately if you observe any unusual transaction or suspect that the Card is being
              misused, altered, or tampered with. We are not responsible for monitoring the use of
              the Card or reporting to you any suspicious or unauthorised transactions or any misuse
              of the Card.
            </p>
            <ol start="9">
              <li>
                <p align="JUSTIFY">
                  <strong>Expenditure Limit on Account</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              We will notify you of the Expenditure Limit at the time you open the Account. The
              Expenditure Limit initially notified to you will be determined, at our sole and
              absolute discretion, based on the amount of cash deposit you provide to us when you
              submit your Application (if any) amongst other factors that we may consider from time
              to time. We may vary the Expenditure Limit at any time and at our sole and absolute
              discretion, without giving you any reason, by notice to you in writing. Such variation
              will take effect at the time specified in the notice. The Expenditure Limit will also
              be set out in each Statement. You must ensure that the Balance at any time does not
              exceed the Expenditure Limit. If it does, you must immediately pay to us the amount
              that exceeds the Expenditure Limit. We will also charge to your Account an over-limit
              fee for each month (or part thereof) that the Expenditure Balance exceeds the
              Expenditure Limit. We may also suspend your Account and the Card without any prior
              notice to you if the Balance exceeds the Expenditure Limit.
            </p>
            <ol start="10">
              <li>
                <p align="JUSTIFY">
                  <strong>Non-acceptance of Card</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              We are not responsible if, for any reason, the Supplier refuses to accept a Card,
              refuses complete a transaction under the Card, or refuses to offer any discount or
              rebate described in clause 4, whether in part or in whole. We shall not be liable for
              any loss or damage caused to you directly or indirectly, and arising out of or in
              connection with any act or omission of the Supplier or our appointed agents,
              contractors, suppliers and/or the operator of any Service Station, including, without
              limitation, any refusal to honour or accept the Card by the Supplier. Any dispute or
              claim that you may have with or against the Supplier, or operator of any Service
              Station, or our appointed agents, contractors or suppliers shall not affect the
              performance by you of your obligations under these terms and conditions.
            </p>
            <ol start="11">
              <li>
                <p align="JUSTIFY">
                  <strong>Responsibility for goods and services supplied</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              We are not responsible in any respect for any goods or services acquired by any person
              using a Card or otherwise. All conditions and warranties, whether expressed or implied
              and whether arising under any law, regulation or otherwise, as to the condition,
              suitability, quality, fitness or safety of any of the Products are expressly excluded
              to the extent permitted by law. You must resolve any complaint or dispute relating to
              goods or services (including, without limitation, relating to the Products’ supply,
              quality or use) acquired by any person using a Card or otherwise directly with the
              supplier of the goods or services. Your obligation to pay amounts charged to your
              Account will not be affected or limited by any such complaint or dispute.
            </p>
            <ol start="12">
              <li>
                <p align="JUSTIFY">
                  <strong>Statements</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              We will send a Statement to you after the end of each monthly billing period (such
              period to be determined by us in our sole and absolute discretion) if:
            </p>
            <ol type="a">
              <li>
                <p align="JUSTIFY">
                  any amount has been charged or credited to your Account since the date your
                  Account was opened or the date of your previous Statement; or
                </p>
              </li>
              <li>
                <p align="JUSTIFY">there is any amount outstanding on your Account.</p>
              </li>
            </ol>
            <p align="JUSTIFY">
              The Statement will show the total amount payable by you to us (this is the Balance or
              the “Amount Due” shown on the Statement) for the billing period and the date by which
              payment must be received by us in order to avoid the charging of a Late Payment
              Charge. You will be deemed to have received each Statement upon the earlier of its
              actual receipt by you or the time set out under the ‘Notices’ clause in these terms
              and conditions. You will notify us within seven (7) days of the date payment is due of
              any errors or discrepancies in the Statement. If you do not notify us within this
              seven-day period, you are deemed to have accepted that the Account transaction
              information shown in the Statement is valid and correct and is conclusive evidence of
              the amount due from you to us, and that you are liable to pay us such amount by the
              date on which payment should be received by us.
            </p>
            <ol start="13">
              <li>
                <p align="JUSTIFY">
                  <strong>Account settlement</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              The Balance shown on a Statement is due and payable to us on the date specified or
              described in the Statement. We may also, at any time, demand immediate payment of any
              charge made to the Account by sending a written demand to you. If we do this, the
              amount demanded becomes immediately due for payment. The entire outstanding Balance on
              your Account together with the amount of any outstanding purchases of Products
              effected by the use of the Card but not yet charged to the Account shall,
              notwithstanding any other terms of these terms and conditions, become immediately due
              and payable in full on the termination of the Account or the Card or if a Notification
              Event occurs.
            </p>
            <p align="JUSTIFY">
              You will pay amounts to us by direct debit, autopay or any other method as we may
              notify you of from time to time. We do not accept cash payments. Payments made after 4
              p.m. (Singapore time) on a Business Day or on a day that is not a Business Day will be
              treated as if made on the following Business Day. We reserve the right to, at any
              time, stop accepting payments through a particular payment mode or manner. If so, we
              will notify you of the same. All payments must be made in Singapore dollars. If you
              make a payment and we (acting reasonably) cannot identify the Account to which the
              payment relates, we will not be responsible for the payment not being credited to your
              Account. We may, at our sole and absolute discretion, accept late or part payments or
              a payment described as being in full or in settlement of a dispute. Our agreement to
              do so does not constitute a waiver of any of our rights under these terms and
              conditions or at law, and does not mean that we agree to a variation to these terms
              and conditions. We accept no responsibility in respect of payments sent to us by post
              or payments made to other persons for transmission to us. All payments are at your
              risk until received by us. If we receive a cheque, cashier’s order, draft or other
              payment instrument from you or from another person on your behalf which is not
              honoured in full for any reason, you are liable to pay us the dishonoured payment fee,
              the dishonoured amount plus our collection costs and legal fees. Similarly, if you
              have arranged to pay us through a direct debit facility of any kind with any financial
              institution and our debit to your account with that financial institution is not
              permitted, authorised or honoured in full for any reason, you agree to pay us the
              dishonoured payment fee, the dishonoured amount plus our reasonable collection costs
              and legal fees.
            </p>
            <ol start="14">
              <li>
                <p align="JUSTIFY">
                  <strong>Cash deposit</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              You are required to provide security from time to time by way of cash deposit to
              secure all or any amounts due from you, the Expenditure Limit that may be granted to
              you, and due observance and performance by you of the obligations under these terms
              and conditions and the Application. The cash deposit shall be for such amount as may
              be required by us from time to time, and, in any event, shall not be less than the
              Expenditure Limit. We will notify you of the initial amount of cash deposit required
              when you submit the Application. We are entitled to apply the cash deposit to settle
              all or any amounts due from you and to cover all losses and damages that may be
              suffered by us due to your failure to observe or perform any of your obligations under
              these terms and conditions and the Application without prior notice to you. Provided
              that you have duly settled all amounts and observed and performed all your obligations
              under these terms and conditions and the Application, the cash deposit (or any unused
              portion thereof) will be returned to you, without interest, as soon as practicable
              after termination of use of the Card and the Account.
            </p>
            <ol start="15">
              <li>
                <p align="JUSTIFY">
                  <strong>Fees and charges</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              <em>
                <strong>Annual fee</strong>
              </em>
            </p>
            <p align="JUSTIFY">
              Unless waived by us at our sole and absolute discretion, you must pay us an annual fee
              for each Card issued on your account as stated in the Fee Schedule. The initial annual
              fee for the first 12-month period is due upon your account opening (unless waived) and
              on each subsequent anniversary thereafter. This annual fee is payable for the use of
              the Card. There is no entitlement to a refund or pro rata payment of any fees if an
              account is closed.
            </p>
            <p align="JUSTIFY">
              <em>
                <strong>Late payment fees</strong>
              </em>
            </p>
            <p align="JUSTIFY">
              Any reference in this clause to “overdue amount” includes any Late Payment Charge that
              has been charged to your Account and remains unpaid.
            </p>
            <p align="JUSTIFY">
              A Late Payment Charge will be charged to your Account if you do not pay the Balance
              shown on a Statement by the date specified or described in the Statement or you do not
              pay an amount charged to your Account when demanded by us. The Late Payment Charge
              will comprise a late fee and an administration fee as set out in the Fee Schedule.
            </p>
            <p align="JUSTIFY">
              <em>
                <strong>Other ancillary fees</strong>
              </em>
            </p>
            <p align="JUSTIFY">
              We may also charge you any or all of the fees set out in the Fee Schedule.
            </p>
            <ol start="16">
              <li>
                <p align="JUSTIFY">
                  <strong>Refunds</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              We may, at our sole and absolute discretion, credit your Account with a refund for any
              amount charged to your Account in respect of a transaction if we receive a valid
              credit voucher or other refund verification that is acceptable to us from the
              Supplier.
            </p>
            <ol start="17">
              <li>
                <p align="JUSTIFY">
                  <strong>Application of payments</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              Any amount we receive from you will be applied in whole or in part, and in any order
              we choose, to amounts charged to your Account or any other Account that you have with
              us under which there are outstanding payments. We may, at any time by written notice
              to you, apply any credit balance (whether or not then due) to which you are at any
              time beneficially entitled on any Account, or which is held to your order, in or
              towards satisfaction of any unpaid sum then due from you to us. We are not obliged to
              exercise our rights of set-off and appropriation under this clause, which are without
              prejudice to, and in addition to, any other rights or lien to which we are at any time
              otherwise entitled (whether by operation of law, contract or otherwise).
            </p>
            <ol start="18">
              <li>
                <p align="JUSTIFY">
                  <strong>Card cancellation and closure of Account</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              Notwithstanding any other provision in these terms and conditions, we may terminate,
              revoke, suspend or cancel your Account or any Card at any time at our sole and
              absolute discretion without providing you or the Cardholder with prior notice or any
              reason. We will also cancel a Card if you or the relevant Cardholder asks us to, or if
              you or the relevant Cardholder gives us notification under clause 8 of these terms and
              conditions. If you ask us to close your Account, or we decide to close it at our sole
              and absolute discretion (see below), we may cancel all Cards immediately without
              further notice to you or the Cardholder. Any Card that is cancelled must not be used,
              and you must immediately cut it in half and promptly return it to us. Subject to
              clause 8, the cancellation of a Card does not affect or limit your obligations under
              these terms and conditions, including your obligation to pay amounts charged to your
              Account whether in respect of transactions on your Account using the cancelled Card
              (before or after it is cancelled) or otherwise.
            </p>
            <p align="JUSTIFY">Your Account will be closed when:</p>
            <ol type="a">
              <li>
                <p align="JUSTIFY">
                  you ask us in writing to close it, or if we decide, in our sole and absolute
                  discretion, to close it; and
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  you have cut in half and returned to us all Cards that may be used to access the
                  Account or, where not all Cards are so returned, explained to our satisfaction why
                  the outstanding Cards cannot be returned; and
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  all amounts outstanding on the Account have been paid in full.
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              Closure of the Account does not affect or limit your obligations under these terms and
              conditions.
            </p>
            <ol start="19">
              <li>
                <p align="JUSTIFY">
                  <strong>Suspension</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">We can suspend the Account or a Card at any time without notice:</p>
            <ol type="a">
              <li>
                <p align="JUSTIFY">
                  if you are in default under these terms and conditions (including, without
                  limitation, in default of any payment obligation); or
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  if we suspect that a Card or the Account has been used fraudulently or unlawfully
                  by you or a third party; or
                </p>
              </li>
              <li>
                <p align="JUSTIFY">to prevent loss to us and/or you.</p>
              </li>
            </ol>
            <p align="JUSTIFY">
              If we do this, you and each Cardholder must not use the relevant Card or the Account
              until such time as we advise you that the Account or the relevant Card has been
              reactivated or reinstated. The suspension of the Account or a Card does not otherwise
              affect or limit your obligations under these terms and conditions.
            </p>
            <p align="JUSTIFY">
              Once your Account and/or Card is suspended, we shall have the sole and absolute
              discretion to suspend your account in GOGOX for a period as determined by us from time
              to time.
            </p>
            <p lang="en-GB" align="JUSTIFY">
              <a name="_GoBack"></a>
              <br />
            </p>
            <ol start="20">
              <li>
                <p align="JUSTIFY">
                  <strong>Variation</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              We reserve the right to add to, delete and/or to vary any of these terms and
              conditions (including varying a fee or charge or imposing a new fee or charge) at any
              time upon notice to you (which is done by posting a notice on our website, which shall
              be effective and binding on you upon such posting). You agree to regularly review
              these terms and conditions, hence the continued use of the Card after any changes made
              to the terms and conditions, shall constitute your consent and acceptance without
              reservation to such changes. If you do not accept any proposed change, you or the
              Cardholder shall return the Card or give notice of termination of use of the Card to
              us prior to the effective date of such change.
            </p>
            <ol start="21">
              <li>
                <p align="JUSTIFY">
                  <strong>Certificate</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              A certificate signed by one of our authorised officers concerning an amount charged to
              your Account or payable by you under these terms and conditions or concerning any
              other matter in connection with your Account or these terms and conditions will, in
              the absence of manifest error, be conclusive evidence of the amount charged or payable
              or of the other matter
            </p>
            <ol start="22">
              <li>
                <p align="JUSTIFY">
                  <strong>Notices</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              Subject to these terms and conditions, any notice, demand or communication given or
              made under these terms and conditions must be in writing and will be deemed
              effectively given: (a) upon personal delivery to the party to be notified, (b) when
              sent by confirmed electronic mail or facsimile if sent during normal business hours of
              the recipient, if not, then on the next Business Day, (c) three (3) Business Days
              after having been sent by registered or certified mail, return receipt requested,
              postage prepaid, or (d) one (1) Business Day after deposit with a nationally
              recognized overnight courier, specifying next day delivery, with written verification
              of receipt.
            </p>
            <p align="JUSTIFY">
              For the purpose of providing notice, our contact details are (unless we notify you
              otherwise):
            </p>
            <p align="JUSTIFY">Customer Services Manager</p>
            <p align="JUSTIFY">GoGo Energy Singapore Pte Ltd</p>
            <p align="JUSTIFY">
              601 MacPherson Road, #08-10, Grantral Complex @ MacPherson, Singapore 368242
            </p>
            <p align="JUSTIFY">Tel: +65 6245-2800</p>
            <p align="JUSTIFY">
              For the purpose of providing notice, your contact details are (unless you otherwise
              notify us in accordance with clause 23) the details provided in your Application for
              the Account.
            </p>
            <p align="JUSTIFY">
              We may, in our sole and absolute discretion, decide to deliver any notices, demands,
              documents or communications related to these terms and conditions or any notices
              required by applicable law by email or any other electronic means. You hereby consent
              to receive such notices, demands, documents and communications by such electronic
              delivery and agree to participate through an online or electronic system established
              and maintained by us or a third party designated by us.
            </p>
            <ol start="23">
              <li>
                <p align="JUSTIFY">
                  <strong>Change of details</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              You must notify us in writing of any change in the Nominated Vehicle(s) or other
              particulars furnished to us (including particulars set out in the Application or the
              particulars of the Cardholder). Upon such notification being given, the Card relating
              to such Nominated Vehicle(s) or Cardholder shall be destroyed immediately by cutting
              into half, and in any event no later than seven (7) days of the notification you give
              to us. We may, at our sole and absolute discretion, issue a replacement Card showing
              the changed particulars and invalidate the existing Card and impose such fee as is
              necessary. Pending the issuance of the replacement Card, you shall continue to be
              liable to pay for all charges incurred in relation to or arising from the use of the
              existing Card.
            </p>
            <p align="JUSTIFY">
              If we ask you to provide us with the name and address of any person authorised by you
              to use a Card, you must do so immediately and, in any event, within three (3) days of
              our request to you.
            </p>
            <ol start="24">
              <li>
                <p align="JUSTIFY">
                  <strong>Disputes</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              If you disagree with any amount charged to the Account, you should notify us as soon
              as possible in writing. You must provide us, either together with your notification or
              subsequently, if requested by us, with the reasons for your claim and with any
              supporting evidence or documents. Nothing in this clause 24 entitles you to withhold
              payment from us in respect of the amount in dispute or in respect of any amount due to
              us.
            </p>
            <ol start="25">
              <li>
                <p align="JUSTIFY">
                  <strong>Miscellaneous</strong>
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              The laws of Singapore govern these terms and conditions. You submit to the exclusive
              jurisdiction of Singapore courts in connection with matters concerning these terms and
              conditions. A person who is not a party to these terms and conditions shall have no
              rights under the Contracts (Rights of Third Parties) Act (Cap 53B) of Singapore to
              enforce or enjoy the benefit of these terms and conditions. You may not assign or
              transfer any of your rights or obligations under these terms and conditions, the
              Application or in respect of your Account without our prior written consent. We may,
              at any time in our sole and absolute discretion, and without your consent, assign or
              transfer to any person any of our rights or obligations under these terms and
              conditions, the Application or in respect of your Account (including assigning the
              right for any non-Affiliaites or for any of our Affiliates to collect payment and/or
              to deduct current or future payments that you may owe to any of our Affiliates from
              time to time for the purpose of settling the Balance payable to us). Time will be of
              the essence in relation to your obligations under these terms and conditions and the
              Application. No failure to exercise, nor any delay in exercising, any right, power or
              remedy by us, operates as a waiver. A single or partial exercise by us of any right,
              power or remedy does not preclude any other or further exercise of that or any other
              right, power or remedy. A waiver is not valid or binding on us unless in writing. Our
              rights, powers and remedies under these terms and conditions are in addition to, and
              do not exclude or limit, any right, power or remedy provided by law or equity or by
              any other agreement or instrument. Any provision of, or the application of any
              provision of, these terms and conditions and the Application which is:
            </p>
            <ol type="a">
              <li>
                <p align="JUSTIFY">
                  prohibited in a jurisdiction is, in that jurisdiction, ineffective only to the
                  extent of that prohibition; and
                </p>
              </li>
              <li>
                <p align="JUSTIFY">
                  held to be void, illegal or unenforceable in any jurisdiction does not affect the
                  validity, legality or enforceability of that provision in any other jurisdiction
                  or of the remaining provisions in that or any other jurisdiction and may be
                  severed without affecting the enforceability of the other provisions in these
                  terms and conditions or the Application.
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              Please note that these fees and charges may be varied by notice to you in writing.
            </p>
            <p>
              <strong>Annual fee</strong>
            </p>
            <table width="412" border="2" cellPadding="8" cellSpacing="0">
              <colgroup>
                <col width="220" />
                <col width="155" />
              </colgroup>
              <tbody>
                <tr valign="TOP">
                  <td width="220">
                    <p>
                      <em>
                        <strong>Payment method</strong>
                      </em>
                    </p>
                  </td>
                  <td width="155">
                    <p>
                      <em>
                        <strong>Fee</strong>
                      </em>
                    </p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width="220">
                    <p>GIRO/credit card</p>
                  </td>
                  <td width="155">
                    <p>S$30 per card/per year</p>
                    <p>(unless waived at our discretion)</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p align="JUSTIFY">
              All outstanding amounts must be settled by GIRO or credit card unless we agree
              otherwise in writing at our sole discretion. Payments other than GIRO or credit card
              may incur a billing administration fee of S$2. There is no entitlement to a refund or
              pro rata payment of any fees if an account is closed.
            </p>
            <p>
              <strong>Late payment fees</strong>
            </p>
            <table width="412" border="2" cellPadding="8" cellSpacing="0" rules="NONE">
              <colgroup>
                <col width="209" />
                <col width="166" />
              </colgroup>
              <tbody>
                <tr valign="TOP">
                  <td width="209">
                    <p>
                      <em>
                        <strong>
                          Administration fee
                          <br />
                          (late payments)
                        </strong>
                      </em>
                    </p>
                  </td>
                  <td width="166">
                    <p>S$10</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width="209">
                    <p>
                      <em>
                        <strong>Late fee</strong>
                      </em>
                    </p>
                  </td>
                  <td width="166">
                    <p>3% of the overdue amount (or any part thereof) per month</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <strong>Ancillary fees</strong>
            </p>
            <p align="JUSTIFY">
              In addition to the fees and charges that you must pay to us under these terms and
              conditions, you agree to pay us the fees and charges below along with such other fees
              and charges determined by and notified and charged to you:
            </p>
            <table width="412" border="2" cellPadding="8" cellSpacing="0">
              <colgroup>
                <col width="209" />
                <col width="166" />
              </colgroup>
              <tbody>
                <tr valign="TOP">
                  <td width="209">
                    <p>
                      <em>
                        <strong>Card issue fee</strong>
                      </em>
                    </p>
                    <p>
                      <em>(per card)</em>
                    </p>
                  </td>
                  <td width="166">
                    <p>S$2</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width="209">
                    <p>
                      <em>
                        <strong>Dishonoured payment fee</strong>
                      </em>
                    </p>
                    <p>
                      <em>(per occasion)</em>
                    </p>
                  </td>
                  <td width="166">
                    <p>S$2</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width="209">
                    <p>
                      <em>
                        <strong>Paper statement fee</strong>
                      </em>
                    </p>
                    <p>
                      <em>(per statement)</em>
                    </p>
                  </td>
                  <td width="166">
                    <p>S$2</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width="209">
                    <p>
                      <em>
                        <strong>Replacement card fee</strong>
                      </em>
                    </p>
                    <p>
                      <em>(per card)</em>
                    </p>
                  </td>
                  <td width="166">
                    <p>S$5</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width="209">
                    <p>
                      <em>
                        <strong>Standard statement reissue fee</strong>
                      </em>
                    </p>
                  </td>
                  <td width="166">
                    <p>S$2 each</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width="209">
                    <p>
                      <em>
                        <strong>Non-standard statement reissue fee</strong>
                      </em>
                    </p>
                  </td>
                  <td width="166">
                    <p>To be quoted</p>
                  </td>
                </tr>
                <tr valign="TOP">
                  <td width="209">
                    <p>
                      <em>
                        <strong>Express postage card delivery fee </strong>
                      </em>
                      <em>(per card)</em>
                    </p>
                  </td>
                  <td width="166">
                    <p>S$5</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p align="JUSTIFY">
              If there is more than one card issued under your Account, card issue fees are payable
              for each additional card issued at the time of issue and, in the case of special
              delivery instructions, an additional administration and delivery fee of up to S$100.
            </p>
            <p align="JUSTIFY">
              Dishonoured payment fees are payable each time there is a dishonoured payment.
            </p>
            <p align="JUSTIFY">
              A paper statement fee is charged on a monthly basis should you request to receive your
              statements via post. You may request to receive your statement electronically at any
              time to avoid this fee.
            </p>
            <p align="JUSTIFY">
              Replacement card fees are payable per replacement card required to be issued.
            </p>
            <p align="JUSTIFY">
              Statement reissue fees are payable in respect of the provision of standard or
              non-standard copies of documents relating to the account (including previous
              statements).
            </p>
            <p align="JUSTIFY">
              We may charge to your Account, in addition to any other amount payable under these
              terms and conditions, the amount of any government duties, taxes (including goods and
              services tax) and charges now or in the future charged or payable in relation to or in
              connection with:
            </p>
            <ol type="a">
              <li>
                <p align="JUSTIFY">your Account;</p>
              </li>
            </ol>
            <ol type="a" start="2">
              <li>
                <p align="JUSTIFY">any amount payable under these terms and conditions;</p>
              </li>
            </ol>
            <ol type="a" start="3">
              <li>
                <p align="JUSTIFY">
                  any transaction entered into in relation to a Card or using a Card; or
                </p>
              </li>
            </ol>
            <ol type="a" start="4">
              <li>
                <p align="JUSTIFY">
                  the supply of any thing (including any goods or services) under these terms and
                  conditions or in connection with your Account, whether or not you are principally
                  liable for the duties, taxes (including goods and services tax) or charges.
                </p>
              </li>
            </ol>
            <p align="JUSTIFY">
              We reserve the right to vary all fees and charges at any time by notice to you in
              writing. If you do not accept any proposed variation, you or the Cardholder shall
              return the Card or give notice of termination of use of the Card to us prior to the
              effective date of such change.
            </p>
          </div>
        </div>
        <SubFooter eventBus={this.props.eventBus} />
      </div>
    );
  }
}
