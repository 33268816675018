import React from 'react';
import '../style/privacy-policy';
import SubFooter from './sub-footer';
import { EventEmitter } from 'fbemitter';

export default class PrivacyPolicy extends React.Component {
  props: {
    eventBus: EventEmitter,
  };

  state: {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="legal-term-header"></div>
        <div className="privacy-policy">
          <div className="bounded-width content">
            <h1>私隱政策</h1>
            <p>
              本私隱政策旨在解釋，當閣下使用本網站或其他相關平臺時，GOGO ENERGY
              LIMITED、其附屬公司、關聯公司及有聯繫公司（以下簡稱
              &ldquo;高高&rdquo;）如何收集、持有、處理、使用、分享和保護您的個人信息。本私隱政策涵蓋由高高所持有、運營、提供及/或導致存有的&ldquo;在線&rdquo;（例如，互聯網和移動服務）和&ldquo;線下&rdquo;（例如，通過電話或個人親自收集資料）活動。本私隱政策具體論述了高高在《個人資料
              （私隱）條例》（香港法例第 486
              章）（以下簡稱&ldquo;相關條例&rdquo;）下關於個人資料隱私的義務。&nbsp;
            </p>
            <p>
              請仔細閱讀本私隱政策以瞭解高高的私隱政策和實踐操作，若閣下有不明瞭處或有任何疑問，請聯絡高高。
            </p>
            <ol>
              <li>
                閣下有權拒絕提供此高高所要求的個人資料。請注意，如閣下未能提供此等個人資料，高高或許不能向閣下提供其產品或服務。一旦閣下向高高提供閣下的個人資料，即等同閣下已同意高高根據本通知獲取和使用該等個人資料。
              </li>
              <li>高高將使用閣下的個人資料作下列用途:</li>
              <ol style={{ listStyleType: 'lower-roman' }}>
                <li>為閣下提供的各種服務與產品的日常運作；</li>
                <li>進行信用檢查；</li>
                <li>向閣下或為閣下提供擔保人的人仕追收欠款；</li>
                <li>遵守法律所要求之披露資料的責任；</li>
                <li>
                  向閣下推銷和/或交互推銷有關高高所認為客戶可能感興趣的任何產品或服務（包括高高、其控股公司，同系附屬公司，子公司，聯營公司及其業務夥伴的產品和服務）；
                </li>
                <li>核實閣下及直接付款銀行帳戶持有人之身份；及</li>
                <li>設計適合客戶使用的服務或產品。</li>
              </ol>
              <li>
                高高將會對閣下提供的個人資料保密，但可向下列各方提供該等資料：
              </li>
              <ol style={{ listStyleType: 'lower-roman' }}>
                <li>所有高高成員;</li>
                <li>
                  向高高業務運作有關的行政、電訊、收賬、信用證明、廣告、市場調查、電腦、賬務、轉賬或其他服務的任何代理人、承包商或服務提供者；
                </li>
                <li>
                  業務夥伴，以便客戶獲悉高高認為客戶可能會感興趣的產品或服務（包括高高及其業務夥伴的產品或服務）；及
                </li>
                <li>
                  對高高負有保密責任並已承諾對上述資料保密的任何其他人仕或機構。
                </li>
              </ol>
              <li>
                直接促銷 -
                高高將繼續為閣下提供最新產品、服務推廣計劃之資料，若閣下不欲收取，必須以書面通知高高資料保護主任。如閣下欲終止高高或第（3）項所述人士使用其「個人資料」包括但不限於姓名,
                地址,
                電郵及電話號碼作直銷用途，必須按第（10）項通知高高之資料保護主任。
              </li>
              <li>閣下有權要求：</li>
              <ol style={{ listStyleType: 'lower-roman' }}>
                <li>
                  查閱高高及第（3）項提及之人仕所持關於閣下或閣下賬戶的資料，並要求高高及第（3）項提及之人仕提供、修訂或更正有關資料;
                </li>
                <li>
                  確定高高及第（3）項提及之人仕處理個人資料的政策及做法;及
                </li>
                <li>停止從高高及第（3）項提及之人仕獲取推銷資料。</li>
              </ol>
              <li>
                按照《個人資料（私隱）條例》
                ，高高有權就任何查閱或更改資料的要求收取合理費用而閣下須交付有關費用。
              </li>
              <li>
                閣下個人資料的保存時間，不會超過高高業務所需或法律所要求的時間。
              </li>
              <li>高高有權不時要求閣下提供進一步資料作第（2）項所述的用途。</li>
              <li>
                高高僅會使用閣下的個人資料作第（2）及（3）項所述的用途；高高將盡其能力保護閣下的個人資料，防止未獲准許的或意外的查閱、處理或刪除。
              </li>
            </ol>
            <p>
              所有查詢或更正個人資料或查詢高高持有人之個人資料之類別、政策和執行慣例的要求，圴應以書面形式通知高高之資料保護主任，地址為GOGO
              ENERGY LIMITED, P.O. Box No.62475, Kwun Tong Post Office。
            </p>
          </div>
        </div>
        <SubFooter eventBus={this.props.eventBus} />
      </div>
    );
  }
}
