import React from 'react';
import '../style/header';
import { Col, Card, Button } from 'antd';
import { FlexRow, Responsive } from '../component';
import { translate } from 'react-i18next';
import autobind from 'autobind-decorator';
import SubFooter from './sub-footer';

import '../style/cta-2.sass';

@translate()
export default class Cta extends React.Component {
  state: {
    slideIndex: number,
  };

  @autobind
  onCheckNowClicked() {
    this.props.eventBus.emit('clickApplyNow');
  }

  constructor() {
    super();
    this.state = {
      slideIndex: 0,
    };
  }

  @autobind
  slideChanged(idx) {
    this.setState({ slideIndex: idx });
  }

  render() {
    const { t } = this.props;

    const cards = [
      {
        title: t('cta_two.slide_1_title'),
        description: t('cta_two.slide_1_description'),
        image: require('../images/personal-card.svg'),
      },
      {
        title: t('cta_two.slide_2_title'),
        description: t('cta_two.slide_2_description'),
        image: require('../images/company-card.svg'),
      },
      {
        title: t('cta_two.slide_3_title'),
        description: t('cta_two.slide_3_description'),
        image: require('../images/other-service.svg'),
      },
    ];

    return (
      <div className="cta-2">
        <div className="col-1-container  ">
          <FlexRow align="center" justify="center">
            <Col xs={{ span: 24 }} sm={{ span: 24 }} xl={{ span: 16 }} className="slogan center">
              <p className="title banner-head black slogan-1">{t('cta_two.slogan_one')}</p>
              <p className="title body-head black slogan-2">{t('cta_two.slogan_two')}</p>

              <Responsive id="cta-2-apply-btn" type={'desktop'}>
                <Button type="cyan" size="large" onClick={this.onCheckNowClicked}>
                  {t('btn.apply_now')}
                </Button>
              </Responsive>
              <Responsive id="cta-2-apply-btn" type={['tablet', 'mobile']}>
                <FlexRow justify="center" align="middle" className="">
                  <Col xl={3} sm={6} xs={24} className="padded-horizontal">
                    <Button
                      className="full-width"
                      type="cyan"
                      size="large"
                      onClick={this.onCheckNowClicked}
                    >
                      {t('btn.apply_now')}
                    </Button>
                  </Col>
                </FlexRow>
              </Responsive>
            </Col>
          </FlexRow>

          <FlexRow align="center" justify="space-around">
            {cards.map((card, idx) => (
              <Col key={'cat_2_card_' + idx} xl={6} sm={8} xs={24} className="padded-horizontal">
                <Card
                  cover={
                    <img
                      src={card.image}
                      alt={card.name}
                      title={card.name}
                      className="card-image"
                    />
                  }
                  bordered={false}
                >
                  <Card.Meta
                    className="center"
                    title={<p className="card-title body-head center">{card.title}</p>}
                    description={<p className="body-head black center">{card.description}</p>}
                  />
                </Card>
              </Col>
            ))}
          </FlexRow>
        </div>
        <div className="divider-image-container"></div>

        <SubFooter eventBus={this.props.eventBus} />
      </div>
    );
  }
}
