import React from 'react';

import { Col, Button } from 'antd';
import { FlexRow, Responsive } from '../component';
import { translate } from 'react-i18next';
import autobind from 'autobind-decorator';
import '../style/sub-footer.sass';

@translate()
export default class SubFooter extends React.Component {
  @autobind
  onCheckNowClicked() {
    this.props.eventBus.emit('clickApplyNow');
  }

  render() {
    const { t } = this.props;

    return (
      <div className="sub-footer">
        <div className=" bounded-width padded-vertical">
          <div className="">
            <Responsive id="cta-2-apply-btn" type={'desktop'}>
              <FlexRow justify="center" align="middle">
                <Col span={8}>
                  <Button
                    className="full-width btn-apply bold"
                    type="info"
                    onClick={this.onCheckNowClicked}
                  >
                    <h1 className="bold">{t('btn.apply_now')}</h1>
                  </Button>
                </Col>
              </FlexRow>
            </Responsive>
            <Responsive id="cta-2-apply-btn" type={['tablet', 'mobile']}>
              <FlexRow justify="center" align="middle">
                <Col span={20} style={{ textAlign: 'center' }}>
                  <Button
                    className="full-width btn-apply bold"
                    type="info"
                    onClick={this.onCheckNowClicked}
                  >
                    <Responsive id="cta-2-apply-btn-btn" type={['tablet']}>
                      <h1 className="bold">{t('btn.apply_now')}</h1>
                    </Responsive>
                    <Responsive id="cta-2-apply-btn-btn" type={['mobile']}>
                      <p className="bold">{t('btn.apply_now')}</p>
                    </Responsive>
                  </Button>
                </Col>
              </FlexRow>
            </Responsive>
          </div>
        </div>
      </div>
    );
  }
}
