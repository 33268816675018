import React from 'react';
import '../style/app.sass';

import Header from './header';
import { Switch, Route, Redirect } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import Footer from './footer';
import ApplicationForm from './application-form';
import IframeModal from './iframe-modal';
import { EventEmitter } from 'fbemitter';
import { Layout } from 'antd';
import Landing from './landing';
import PrivacyPolicy from './privacy-policy';
import Usage from './usage';
import TermAndCondition from './tnc';
import DataProtection from './data-protection';
import SgDriver from './sg-driver';
import ReferralProgram from './referral-program';
import ReferralProgramRegister from './referral-program-register';

export default class App extends React.Component {
  constructor() {
    super();
    this.eventBus = new EventEmitter();
    this.state = {};
  }

  componentDidMount() {
    console.log('deployed');
    this.eventBus.addListener('checkPrice', () => {});

    this.eventBus.addListener('clickApplyNow', () => {
      window.open(process.env.URL_APPLY_NOW, '_blank');
    });

    this.eventBus.addListener('clickContact', () => {
      window.open(process.env.CONTACT_URL, '_blank');
    });
  }

  render() {
    return (
      <BrowserRouter>
        <Layout className="container">
          <Header eventBus={this.eventBus} />
          <div className="content-container" id="content-container">
            <Switch>
              <Route exact path="/" component={() => <Landing eventBus={this.eventBus} />} />
              <Route exact path="/usage" component={() => <Usage />} />
              <Route
                exact
                path="/privacy"
                component={() => <PrivacyPolicy eventBus={this.eventBus} />}
              />
              <Route
                exact
                path="/t_c.html"
                component={() => <TermAndCondition eventBus={this.eventBus} />}
              />
              <Route
                exact
                path="/dataprotection.html"
                component={() => <DataProtection eventBus={this.eventBus} />}
              />
              <Route
                exact
                path="/sgdrivers"
                component={() => <SgDriver eventBus={this.eventBus} />}
              />
              <Route
                exact
                path="/ggereferral-register"
                component={() => <ReferralProgramRegister eventBus={this.eventBus} />}
              />
              <Route
                exact
                path="/ggereferral"
                component={() => <ReferralProgram eventBus={this.eventBus} />}
              />
              <Redirect from="*" to="/" />
            </Switch>
            <Footer eventBus={this.eventBus} />
            <ApplicationForm eventBus={this.eventBus} />
            <IframeModal eventBus={this.eventBus} />
          </div>
        </Layout>
      </BrowserRouter>
    );
  }
}
