import { Col, Icon, Layout } from 'antd';
import autobind from 'autobind-decorator';
import { EventEmitter } from 'fbemitter';
import React from 'react';
import { translate } from 'react-i18next';
import { withRouter } from 'react-router';
import { FlexRow, Responsive } from '../component';
import '../style/footer';
import '../style/header';

const AntdFooter = Layout.Footer;

@translate()
@withRouter
export default class Footer extends React.Component {
  props: {
    history: any,
    eventBus: EventEmitter,
  };

  @autobind
  goGgv() {
    window.open('//bit.ly/2LKsNoM', '_blank');
  }

  render() {
    const { t } = this.props;

    const ggvLogo = require('../images/footer_logo_gogovan.png');
    const ggeLogo = require('../images/gge-logo2.png');

    const col1 = [
      { label: t('footer.about_us'), url: process.env.FOOTER_URL_ABOUT_US },
      { label: t('footer.gge'), url: process.env.FOOTER_URL_GGE },
      { label: t('footer.q_a'), url: process.env.MENU_URL_QA },
      // { label: t('footer.my_account'), url: 'https://portal.gogoxenergy.sg/', target: '_blank' },
      // { label: t('footer.check_usage'), url: process.env.FOOTER_URL_CHECK_USEAGE },
      { label: t('footer.tips'), url: process.env.FOOTER_URL_TIPS },
      {
        label: t('footer.card_terms'),
        onClick: () => this.props.history.push('/t_c.html'),
      },
      {
        label: t('footer.privacy_terms'),
        onClick: () => this.props.history.push('/dataprotection.html'),
      },
    ];

    const col2 = [
      // { icon: 'flag', label: t('footer.address') },
      {
        icon: 'phone',
        label: t('footer.phone_no'),
        url: 'tel:' + t('footer.phone_no'),
      },
      // { icon: 'printer', label: t('footer.fax_no'), url: 'tel:' + t('footer.fax_no') },
      {
        icon: 'mail',
        label: t('footer.email'),
        url: 'mailto:' + t('footer.email'),
      },
    ];

    return (
      <AntdFooter className="footer">
        <div className="container-1 bounded-width">
          <Responsive id="footer-container-1" type={['mobile']}>
            <FlexRow justify="center" className="links">
              <Col span={24}>
                <h4 className="white bold col-header">{t('footer.col1_title')}</h4>
              </Col>
              <Col span={24}>
                {col1.map((c, idx) => (
                  <a
                    key={`col1_${idx}`}
                    href={c.url || '#'}
                    onClick={e => {
                      if (c.onClick) {
                        e.preventDefault();
                        c.onClick();
                      }
                    }}
                    target={c.target}
                  >
                    <p className="white footer-text">{c.label}</p>
                  </a>
                ))}
              </Col>
              <Col span={24} className="padded-top">
                <h4 className="white bold col-header">{t('footer.col2_title')}</h4>
              </Col>

              <Col span={24}>
                {col2.map((c, idx) => {
                  if (c.url) {
                    return (
                      <a key={`col2_${idx}`} href={c.url}>
                        <p className="white footer-text">
                          <Icon type={c.icon} />
                          &nbsp;
                          {c.label}
                        </p>
                      </a>
                    );
                  }
                  return (
                    <p key={`col2_${idx}`} className="white footer-text">
                      <Icon type={c.icon} />
                      &nbsp;
                      {c.label}
                    </p>
                  );
                })}
              </Col>
              <Col span={24} className="padded-top">
                <img
                  src={ggvLogo.src}
                  srcSet={ggvLogo.srcSet}
                  alt={ggvLogo.name}
                  title={ggvLogo.name}
                  className="padded-vertical"
                  onClick={this.goGgv}
                />
              </Col>
              <Col xs={24} className="padded-top">
                <p className="white footer-text">{t('footer.footer_text')}</p>
              </Col>
              <Col span={20} className="padded-bottom padded-top" />
            </FlexRow>
          </Responsive>
          <Responsive id="footer-container-1" type={['desktop', 'tablet']}>
            <FlexRow justify="space-between" className="">
              <Col xs={{ span: 12 }} xl={{ span: 5 }}>
                <h4 className="white bold col-header">{t('footer.col1_title')}</h4>
              </Col>
              <Col xs={{ span: 12 }} xl={{ span: 6, offset: 1 }}>
                <h4 className="white bold col-header">{t('footer.col2_title')}</h4>
              </Col>
              <Col xs={24} xl={{ span: 10, offset: 2 }} sm={0}>
                <img
                  src={ggvLogo.src}
                  srcSet={ggvLogo.srcSet}
                  alt={ggvLogo.name}
                  title={ggvLogo.name}
                  onClick={this.goGgv}
                />
              </Col>
            </FlexRow>
            <FlexRow justify="space-between" className="padded-bottom links">
              <Col xs={{ span: 12 }} xl={{ span: 5 }}>
                {col1.map((c, idx) => (
                  <a
                    key={`col1_${idx}`}
                    href={c.url || '#'}
                    onClick={e => {
                      if (c.onClick) {
                        e.preventDefault();
                        c.onClick();
                      }
                    }}
                    target={c.target}
                  >
                    <p className="white footer-text">{c.label}</p>
                  </a>
                ))}
              </Col>
              <Col xs={{ span: 12 }} xl={{ span: 6, offset: 1 }}>
                {col2.map((c, idx) => {
                  if (c.url) {
                    return (
                      <a key={`col2_${idx}`} href={c.url}>
                        <p className="white footer-text">
                          <Icon type={c.icon} />
                          &nbsp;
                          {c.label}
                        </p>
                      </a>
                    );
                  }
                  return (
                    <p key={`col2_${idx}`} className="white footer-text">
                      <Icon type={c.icon} />
                      &nbsp;
                      {c.label}
                    </p>
                  );
                })}
              </Col>
              <Col xs={24} xl={{ span: 10, offset: 2 }}>
                <Responsive id="footer-ggv-logo" type={['tablet']}>
                  <img
                    src={ggvLogo.src}
                    srcSet={ggvLogo.srcSet}
                    alt={ggvLogo.name}
                    title={ggvLogo.name}
                    className="padded-vertical"
                    onClick={this.goGgv}
                  />
                </Responsive>
                <p className="white footer-text" style={{ lineHeight: '22px' }}>
                  {t('footer.footer_text')}
                </p>
                <FlexRow justify="space-between" align="middle">
                  <Col span={8} />
                </FlexRow>
              </Col>
            </FlexRow>
          </Responsive>
        </div>
        <div className="container-2">
          <Responsive id="footer-footer-line" type={['tablet', 'desktop']}>
            <FlexRow
              className="padded-vertical footer-line bounded-width"
              justify="space-between"
              align="middle"
              gutter={0}
            >
              <Col span={6}>
                <img
                  className="gge-logo"
                  src={ggeLogo.src}
                  srcSet={ggeLogo.srcSet}
                  alt={ggeLogo.name}
                  title={ggeLogo.name}
                />
              </Col>
              <Col span={8} className="copyright">
                <small>{t('footer.copyright')}</small>
              </Col>
            </FlexRow>
          </Responsive>
          <Responsive id="footer-footer-line" type={['mobile']}>
            <FlexRow
              className="padded-vertical footer-line bounded-width"
              justify="center"
              align="middle"
              gutter={0}
            >
              <Col span={24} className="footer-line-logo">
                <img
                  className="gge-logo"
                  src={ggeLogo.src}
                  srcSet={ggeLogo.srcSet}
                  alt={ggeLogo.name}
                  title={ggeLogo.name}
                />
              </Col>
              <Col span={24} className="copyright">
                <small>{t('footer.copyright')}</small>
              </Col>
            </FlexRow>
          </Responsive>
        </div>
      </AntdFooter>
    );
  }
}
