import { Layout } from 'antd';
import { EventEmitter } from 'fbemitter';
import React from 'react';
import Banner from './banner';
import Cta1 from './cta-1';
import Cta2 from './cta-2';
// import Features1 from './features-1';
import Features from './features';
import Partnership from './partnership';

const { Content } = Layout;

export default class Landing extends React.Component {
  props: {
    eventBus: EventEmitter,
  };

  state: {
    appInfo: any,
  };

  constructor() {
    super();
    this.state = {
      appInfo: null,
    };
  }

  //   componentDidMount() {
  //     window.scrollTo(0, 0);

  //     fetch('/info.json')
  //       .then((resp) => resp.json())
  //       .then((resp) => {
  //         this.setState({ appInfo: resp });
  //       });
  //   }

  render() {
    return (
      <div>
        <Banner eventBus={this.props.eventBus} appInfo={this.state.appInfo} />
        <Content>
          {/* <Counter appInfo={this.state.appInfo} /> */}
          <Partnership />
          {/* <Features1 /> */}
          <Cta1 eventBus={this.props.eventBus} />
          <Features eventBus={this.props.eventBus} />
          <Cta2 eventBus={this.props.eventBus} />
        </Content>
      </div>
    );
  }
}
