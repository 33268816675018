
import React from 'react';
import MediaQuery from 'react-responsive';

export default class Responsive extends React.PureComponent {
    props: {
        type: string,
        id: string
    }

    constructor() {
        super();
        const themeVars = process.env.THEME_VARS;
        this.config = {
            mobile: Number(themeVars['screen-sm'].replace('px', '')) ,
            tablet: Number(themeVars['screen-md'].replace('px', '')),
            desktop: Number(themeVars['screen-xl'].replace('px', ''))
        };

    }
    render() {
        const { type } = this.props;
        let types = type;
        if (typeof type === 'string') {
            types = [type];
        }
        return types.map(type => {
            if (type === 'mobile') {
                return <MediaQuery key={this.props.id + '-mobile'} maxWidth={this.config.mobile} >{this.props.children}</MediaQuery>;
            } else if (type === 'tablet') {
                return <MediaQuery key={this.props.id + '-tablet'} minWidth={this.config.mobile + 1} maxWidth={this.config.desktop} >{this.props.children}</MediaQuery>;
            }
            return <MediaQuery key={this.props.id + '-desktop'} minWidth={this.config.desktop + 1} >{this.props.children}</MediaQuery>;
        });


    }
}
