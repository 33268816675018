import { Button, Carousel } from 'antd';
import autobind from 'autobind-decorator';
import { EventEmitter } from 'fbemitter';
import React from 'react';
import { translate } from 'react-i18next';
import { FlexRow, Responsive } from '../component';
import bannerImages from '../images/banner';
import '../style/banner.sass';
import '../style/header';

@translate()
export default class Banner extends React.Component {
  props: {
    eventBus: EventEmitter,
    appInfo: {},
  };
  state: {
    carouselIdx: number,
  };
  images: any[];

  constructor() {
    super();
    this.images = bannerImages;
    this.state = {
      carouselIdx: 0,
    };
  }
  componentDidMount() {}

  @autobind
  onChange(idx) {
    if (idx !== this.state.carouselIdx) {
      this.setState({ carouselIdx: idx });
    }
  }

  @autobind
  changeImage(carouselIdx) {
    this.setState({
      carouselIdx,
    });
    this.carousel.goTo(carouselIdx);
  }

  @autobind
  onCheckNowClicked() {
    this.props.eventBus.emit('clickApplyNow');
  }

  @autobind
  renderCarousel(images) {
    return (
      <Carousel
        ref={e => {
          if (e) this.carousel = e;
        }}
        dots={false}
        className="title-carousel"
        afterChange={this.onChange}
      >
        {images.map((img, idx) => (
          <div key={`banner-image-${idx}`}>
            <div style={{ backgroundImage: `url(${img.src})` }} className="banner-image">
              {' '}
            </div>
          </div>
        ))}
      </Carousel>
    );
  }

  render() {
    const { t } = this.props;

    return (
      <div className="banner">
        <Responsive id="banner-carousel" type={'desktop'}>
          {this.renderCarousel(this.images['desktop'])}
        </Responsive>
        <Responsive id="banner-carousel" type={'tablet'}>
          {this.renderCarousel(this.images['tablet'])}
        </Responsive>
        <Responsive id="banner-carousel" type={'mobile'}>
          {this.renderCarousel(this.images['mobile'])}
        </Responsive>

        <FlexRow
          justify="flex-start"
          align="middle"
          className="slogan-container no-pointer-events bounded-width"
        >
          <div className=" button-wraper  ">
            <Button
              size="large"
              className="white btn-apply"
              type="info"
              style={{ pointerEvents: 'auto' }}
              onClick={this.onCheckNowClicked}
            >
              {t('btn.apply_now')}
            </Button>
          </div>
          <div className=" button-wraper ">
            <Button
              size="large"
              className="white btn-apply btn-promotion"
              type="info"
              style={{ pointerEvents: 'auto' }}
            >
              {t('btn.latest_promotion')}
            </Button>
            <div className="btn-dropdown-list">
              <ul>
                <li>
                  <a href="/ggereferral" target="_blank">
                    Member referral program
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </FlexRow>

        {/* <OilCalculator eventBus={this.props.eventBus} appInfo={this.props.appInfo} /> */}
      </div>
    );
  }
}
