import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';

i18n
    .use(Backend)
    // .use(LanguageDetector)
    .use(reactI18nextModule)
    .init({
        loadPath: '/locales/{{lng}}/{{ns}}.json',

        fallbackLng: 'en',

        // have a common namespace used around the full app
        ns: ['translation'],
        defaultNS: 'translation',

        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react!!
            format: function(value, format, lng) {
                if (format === 'number') return new Intl.NumberFormat().format(value);
                return value;
            }
        },

        react: {
            wait: true
        }
    });


export default i18n;