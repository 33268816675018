import React from 'react';
import '../style/header';
import { Card, Col, Button } from 'antd';
import { FlexRow, ApplyDropdown, Divider, Responsive } from '../component';
import { translate } from 'react-i18next';
import autobind from 'autobind-decorator';
import '../style/features.sass';

@translate()
export default class Features extends React.Component {
  constructor() {
    super();
    this.state = { mobileCardsLeftOffset: 0 };
  }
  getCardEmptyPlaceholder(noOfCardPerRow, span) {
    const noOfPlaceholders = noOfCardPerRow - (this.cards.length % noOfCardPerRow);
    return [...Array(noOfPlaceholders).keys()].map(i => (
      <Col span={span} key={'feature_card_empty_placeholder_' + i}></Col>
    ));
  }

  @autobind
  onCheckNowClicked() {
    this.props.eventBus.emit('clickApplyNow');
  }

  @autobind
  updateMobileCardLeftOffset() {
    this.setState({
      mobileCardsLeftOffset: (window.innerWidth - 200) /*card width*/ / 2,
    });
  }
  componentDidMount() {
    this.updateMobileCardLeftOffset();
    window.addEventListener('resize', this.updateMobileCardLeftOffset);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateMobileCardLeftOffset);
  }

  render() {
    const { t } = this.props;
    this.cards = [
      {
        image: require('../images/usage.svg'),
        title: t('features.usage_title'),
        description: t('features.usage_description'),
      },
      {
        image: require('../images/cs.svg'),
        title: t('features.cs_title'),
        description: t('features.cs_description'),
      },
      {
        image: require('../images/estatement.svg'),
        title: t('features.statement_title'),
        description: t('features.statement_description'),
      },
      {
        image: require('../images/payment2.svg'),
        title: t('features.payment_title'),
        description: t('features.payment_description'),
      },
    ];

    return (
      <div className="features">
        <div className="anchor" id="features" />
        <div className="bounded-width container-1">
          <FlexRow align="center" justify="center">
            <Col xs={24} sm={12} className="slogan">
              <h1 className="title black">{t('features.slogan_one')}</h1>
              <Divider center={true} />
            </Col>
          </FlexRow>
        </div>
        <div className="padding-horizontal cards-slider ">
          <Responsive id="features" type={['desktop']}>
            <div className="cards-container">
              <FlexRow align="center">
                {this.cards.map((card, idx) => (
                  <Col span={6} key={`features-${idx}`}>
                    <Card cover={<img src={card.image} alt={card.name} title={card.name} />}>
                      <Card.Meta
                        className="center"
                        title={<p className="body-head card-title">{card.title}</p>}
                        description={<p className="card-description">{card.description}</p>}
                      />
                    </Card>
                  </Col>
                ))}
              </FlexRow>
            </div>
          </Responsive>

          <Responsive id="features" type={['tablet']}>
            <div className="cards-container bounded-width">
              <FlexRow align="center">
                {this.cards.map((card, idx) => (
                  <Col span={10} key={`features-${idx}`} style={{ paddingBottom: 46 }}>
                    <Card cover={<img src={card.image} alt={card.name} title={card.name} />}>
                      <Card.Meta
                        className="center"
                        title={<p className="body-head card-title">{card.title}</p>}
                        description={<p className="card-description">{card.description}</p>}
                      />
                    </Card>
                  </Col>
                ))}
                {/* {this.getCardEmptyPlaceholder(2, 7)} */}
              </FlexRow>
            </div>
          </Responsive>

          <Responsive id="features" type={['mobile']}>
            <div className="cards-floating-container">
              <div className="cards-container">
                <div className="cards-scroller no-scollbar">
                  {this.cards.map((card, idx) => (
                    <div
                      key={`features-${idx}`}
                      className="card"
                      style={
                        this.cards.length - 1 === idx
                          ? { marginRight: this.state.mobileCardsLeftOffset }
                          : idx === 0
                          ? { marginLeft: this.state.mobileCardsLeftOffset }
                          : null
                      }
                    >
                      <Card cover={<img src={card.image} alt={card.name} title={card.name} />}>
                        <Card.Meta
                          className="center"
                          title={<p className="body-head card-title">{card.title}</p>}
                          description={<p className="card-description">{card.description}</p>}
                        />
                      </Card>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Responsive>
        </div>
        <div className="bounded-width container-2">
          <FlexRow align="middle" justify="center">
            <Col xl={6} sm={6} xs={24} className="padded-horizontal" align="middle">
              <Button
                onClick={this.onCheckNowClicked}
                className="full-width"
                type="info"
                size="large"
              >
                {t('btn.apply_now')}
              </Button>
            </Col>
          </FlexRow>
        </div>
      </div>
    );
  }
}
