import React from 'react';
import '../style/usage';
import { Icon } from 'antd';
import autobind from 'autobind-decorator';

export default class Usage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isIframeLoaded: false,
      iframeHeight: 490,
    };

    this.iframe = null;
  }

  @autobind
  handleWindowMessage(event) {
    if (typeof event.data.height !== 'undefined') {
      this.setState({
        iframeHeight: event.data.height,
      });
    }
  }

  @autobind
  handleIframeOnLoad() {
    this.setState({
      isIframeLoaded: true,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleWindowMessage);
  }

  componentDidMount() {
    window.addEventListener('message', this.handleWindowMessage);
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="usage">
        {!this.state.isIframeLoaded ? (
          <Icon
            className="usage-loading"
            type="loading"
            theme="outlined"
            style={{ color: '#0087c1' }}
          />
        ) : null}
        <iframe
          style={{
            height: this.state.iframeHeight,
            visibility: this.state.isIframeLoaded ? 'visible' : 'hidden',
          }}
          src={process.env.MENU_URL_CHECK_USAGE}
          scrolling="false"
          onLoad={this.handleIframeOnLoad}
        />
      </div>
    );
  }
}
