import React from 'react';
import '../style/sg-driver';
import { EventEmitter } from 'fbemitter';

export default class SgDriver extends React.Component {
  constructor() {
    super();
    this.ggxlogo = require('../images/ggx-logo.png');
    this.sgdriverTop = require('../images/sgdrivers/sg-driver-top_2400.jpg');
    this.sgdriverMiddle = require('../images/sgdrivers/sg-driver-middle_2400.jpg');
  }
  props: {
    eventBus: EventEmitter,
  };

  state: {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="sg-driver">
        <div className="bounded-width content">
          <img src={this.sgdriverTop.src}></img>
          <a href="https://apply.gogoxenergy.sg/signup?salesperson=SG_DRIVERS" target="_blank">
            <img src={this.sgdriverMiddle.src}></img>
          </a>
          <div className="tac-block">
            <span>Promotional Terms and Conditions</span>
            <ol>
              <li aria-level="1">
                <span>
                  This promotion is only valid for SGDrivers customers/members who successfully
                  register for a fuel card ("Fuel Card") from GOGO Energy Singapore Pte. Limited
                  ("GGE") for the first time ("New Customers"). Existing GGE customers do not
                  qualify.
                </span>
              </li>
              <li aria-level="1">
                <span>
                  The promotion period of this promotion is from 15th Feb 2023 to 31st May 2023
                  (both dates inclusive) ("Promotion Period").
                </span>
              </li>
              <li aria-level="1">
                <span>
                  The following offers of this promotion ("Offers") are only applicable to New
                  Customers and can only be enjoyed once by each New Customer:
                </span>
                <br></br>
                <div className="innerDiv">
                  <span className="innerLi">3.1. Offer 1: </span>{' '}
                  <span>
                    {' '}
                    New Customers can enjoy a one-off SGD20 fuel rebate upon successful registration
                    for a Fuel Card. The SGD20 fuel rebate will be credited to the New Customer's
                    Fuel Card account once the account has been activated.
                  </span>
                </div>
                <div className="innerDiv">
                  <span className="innerLi">3.2. Offer 2: </span>{' '}
                  <span>
                    {' '}
                    New Customers can enjoy an extra one-off SGD30 fuel rebate provided that they
                    pump more than 100 liters of petrol/diesel within 60 days from the date that
                    their Fuel Card is mailed by GGE to the New Customer. The SGD30 fuel rebate
                    amount will be credited to the New Customer's Fuel Card account within the next
                    upcoming month, and once credited, the fuel rebate will only be available for
                    use within a specified period of time (the "Expiry Date") and be automatically
                    used for the New Customer’s next pump. The New Customer will not be eligible to
                    use the fuel rebate if the Expiry Date has passed.
                  </span>
                </div>
              </li>
              <li aria-level="1">
                <span>
                  GGE reserves the right to change, extend, terminate and/or cancel this promotion
                  and amend these terms and conditions at any time without prior notice. In the
                  event of any dispute in relation to this promotion, GGE reserves the right of
                  final decision.
                </span>
              </li>
              <li aria-level="1">
                <span>
                  By participating in this promotion, it means that the New Customer has read and
                  accepted the terms and conditions of GGE (
                  <a
                    style={{ color: 'white' }}
                    href="https://gogoxenergy.sg/t_c.html"
                    target="_blank"
                  >
                    https://gogoxenergy.sg/t_c.html
                  </a>
                  ) and these terms and conditions.{' '}
                </span>
              </li>
              <li aria-level="1">
                <span>
                  All of GGE’s records in relation to this promotion shall be deemed to be accurate
                  and conclusive.
                </span>{' '}
              </li>
              <li aria-level="1">
                <span>
                  The Offers of this promotion may not be redeemed for cash in any way, and may not
                  be re-gifted or transferred.
                </span>{' '}
              </li>
              <li aria-level="1">
                <span>
                  During the Promotion Period, New Customers cannot participate in other GGE
                  promotion activities at the same time (unless GGE states otherwise). For
                  inquiries, please contact the GGE customer service hotline at 6245 2800.
                </span>
              </li>
              <li aria-level="1">
                <span>
                  To enjoy the full benefits of this promotion, all criterias set by both GoGoX
                  Energy and SGDrivers must be fulfilled.
                </span>
              </li>
              <div className="ggx-logo">
                <img src={this.ggxlogo.src}></img>
              </div>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}
