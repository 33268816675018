import React from 'react';
import { Modal } from 'antd';
import { translate } from 'react-i18next';
import autobind from 'autobind-decorator';
import '../style/iframe-modal.sass';
import { EventEmitter } from 'fbemitter';

@translate()
export default class IframeModal extends React.Component {

    state: {
        visible: boolean,
        html: string
    };
    props: {
        eventBus: EventEmitter
    }
    images: any[];

    constructor() {
        super();
        this.state = {
            visible: false,
            html: ''
        };
    }

    componentDidMount() {
        this.props.eventBus.addListener('showPrivacyPolicyModal', () => {
            this.setState({ visible: true, html: require('../../html/privacypolicy.html') });
        });
        this.props.eventBus.addListener('showTcModal', () => {
            this.setState({ visible: true, html: require('../../html/t_c.html') });
        });

    }

    componentWillUnmount() {
        this.props.eventBus.removeAllListeners('showPrivacyPolicyModal');
        this.props.eventBus.removeAllListeners('showTcModal');
    }

    @autobind
    close() {
        this.setState({ visible: false });
    }


    render() {
        const { t } = this.props;
        return <Modal className="iframe-modal" visible={this.state.visible}
            width={'80%'} style={{ height: '80%' }}
            footer={null} closable={true} onCancel={this.close} >
            <div className="content-wrapper">
                <div className="content" dangerouslySetInnerHTML={{ __html: this.state.html }} />
            </div>
        </Modal>;

    }
}